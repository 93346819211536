<template>
  <div>
    <van-picker title="年龄" show-toolbar :columns="Columns" @confirm="onConfirm" @cancel="onCancel" :default-index="17"/>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        Columns: [],
      }
    },
    mounted() {
      //创建年龄选项
      let ageArry = [];
      for (let i = 18; i < 65; i++) {
        ageArry.push({
          id:i,
          text:i+'岁'
        })
      }
      this.Columns = ageArry
    },
    methods: {
      onConfirm(val){
       this.$emit('confirm',val.id)
      },
      onCancel(){
        this.$emit('confirm','')
      }
    }
  }
</script>
<style scoped>

</style>
