<template>
  <div style="position: relative;">
    <div id="echart4" ref="main4"></div>
   <!-- <img src="@/assets/img/pop.png" @click="tipShow=true" class="fourPop" /> -->
    <!-- 弹窗 -->
    <van-overlay :show="tipShow" @click="tipShow = false">
      <pops :texts="popText" @closePop="tipShow = false"></pops>
    </van-overlay>
  </div>
</template>
<script>
  import pops from '@/components/pops.vue'
  export default {
    components: {
      pops
    },
    props: {
      datas: {
        type: Object,
      }
    },
    data() {
      return {
        myChart4: null,
        interval: null,
        tipShow: false,
        popText: ''
      };
    },
    watch: {
      datas: {
        handler(newVal, oldVal) {
          this.getEcharts4(newVal);
        },
        deep: true
      },
      '$store.state.echartsFour': {
        handler(newVal, oldVal) {
          this.getEcharts4(this.datas);
        },
        deep: true
      },
    },
    methods: {
      getEcharts4(val) {
        let that = this;
        let maxAge = 80,
          xData = [],
          data1 = [],
          data2 = [],
          data3 = [],
          data4 = [];
        for (let i = val.age; i < maxAge; i++) {
          xData.push(i.toString());
          data1.push(val.one[i].toString());
          data2.push(val.two[i].toString());
          data3.push(val.three[i].toString());
          data4.push(val.four[i].toString());
        }
        let seriesArry = [],
          legendData = [],
          popTextArry = [];
        if (data1[0] != 0) {
          seriesArry.push({
            name: '社保提供',
            stack: 'total',
            type: 'bar',
            barWidth: 7,
            data: data1,
            color: '#FEDBD2'
          })
          legendData.push('社保提供')
          popTextArry.push('社保提供')
        }
        if (data2[0] != 0) {
          seriesArry.push({
            name: '家人支持',
            stack: 'total',
            type: 'bar',
            barWidth: 7,
            data: data2,
            color: '#D5D5D7'
          })
          legendData.push('家人支持')
          popTextArry.push('社保提供')
        }
        if (data3[0] != 0) {
          seriesArry.push({
            name: '个人储备',
            stack: 'total',
            type: 'bar',
            barWidth: 7,
            data: data3,
            color: '#FF7D5D'
          })
          legendData.push('个人储备')
          popTextArry.push('个人储备')
        }
        if (data4[0] != 0) {
          seriesArry.push({
            name: '生存概率',
            type: 'line',
            smooth: true,
            symbol: 'none',
            yAxisIndex: 1,
            data: data4,
            color: '#447EEF'
          })
          legendData.push('生存概率')
        }
        that.popText = '根据您选择的退休后养老资金来源和模型测算结果，您在退休后的养老资金来源于' + popTextArry.join('、') +
          '。</br>根据国家卫生健康委公布，2021年我国居民人均预期寿命已达78.2岁，提前做好养老规划十分必要。'
        let option = {
          grid: {
            top: 40,
            bottom: 80,
            left: 30,
            right: 25
          },
          legend: {
            selectedMode: false, //取消图例上的点击事件
            left: 'center',
            icon: 'roundRect',
            bottom: 6,
            data: legendData,
            textStyle: {
              color: "#31394D",
              fontSize: 12
            },
            itemHeight: 6,
            itemWidth: 10
          },
          xAxis: [{
            name:'年龄(岁)',
            nameGap: -20 ,
            nameTextStyle: {
              color: "#D5D5D7",
               fontSize: 12,
               verticalAlign: "top",
                     // lineHeight: 28
              padding:[22,0,0,0]
            },
            type: 'category',
            data: xData,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D5D5D7"
              }
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: "#D5D5D7"
              }
            },
            axisLabel: {
              textStyle: {
                color: "#828286",
                fontSize: 13
              },
            },
          }],
          yAxis: [{
              name: '养老资金(万元/年)',
              nameTextStyle: {
                    padding: [0,0,0,40]
                  },
              type: 'value',
              min: 0,
              axisTick: {
                show: true,
                lineStyle: {
                  color: "#D5D5D7"
                }
              },
              axisLabel: {
                textStyle: {
                  color: "#828286",
                  fontSize: 12
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#D5D5D7"
                }
              },
              splitLine: {
                show: false
              }
            },
            {
              name: '生存概率',
              type: 'value',
              min: 0.5,
              max: 1,
              interval: 0.5,
              axisTick: {
                show: true,
                lineStyle: {
                  color: "#D5D5D7"
                }
              },
              axisLabel: {
                textStyle: {
                  color: "#828286",
                  fontSize: 12
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#D5D5D7"
                }
              },
              splitLine: {
                show: false
              }
            }
          ],
          // dataZoom:[{
          //   type:'inside',
          //   filterMode: 'none',
          //   start:0,
          //   end:32
          // }],
          series: seriesArry
        }
        that.myChart4.clear();
        that.myChart4.setOption(option);
        // window.addEventListener("resize", function() {
        //   that.myChart4.resize();
        // });
      }
    },
    // beforeDestroy() {
    //   window.clearInterval(this.interval)
    // },
    mounted() {
      this.myChart4 = this.$echarts.init(this.$refs.main4);
      this.getEcharts4(this.datas);
      // let that = this;
      // //每隔半分钟执行一次
      // that.interval = setInterval(function() {
      //   that.getEcharts4(that.datas);
      // }, 30000)
    },
  };
</script>
<style scoped>
  #echart4 {
    width: 100%;
    height: 250px;
    position: relative;
    z-index: 1;
  }

  .fourPop {
    width: 1.7rem;
    position: absolute;
    left: -2%;
    bottom: 4%;
    z-index: 2;
  }
</style>
