<template>
  <div class="formBox headerBg1 headerBg2" v-if="detial">
    <!-- <div class="formBox headerBg1 headerBg2" > -->
    <div class="headers headers1">
      <img src="@/assets/img/left.png" @click="$router.go(-1)">
      <span>精算养老规划助手</span>
      <img src="@/assets/img/headBg3.png" class="nius">
    </div>
    <div class="reportBox">
      <div class="reportTitle">
        <div class="reportTitlleImg">
          <img src="@/assets/img/Icon1.png" />
        </div>
        <span>第一步<label>|</label>做体检<br />养老画像综合评估</span>
      </div>
      <div class="examination">
        <div class="examinationImg">
          <img src="@/assets/img/nanImg.png" v-if="forms.sex=='M'" />
          <img src="@/assets/img/nvImg.png" v-else />
        </div>
        <div class="examinationCont">
          <div class="examinationContName">
            <span>当前年龄</span>
            <label>{{forms.inputAge}}岁</label>
          </div>
          <div class="examinationContText">
            {{detial.textAverageLife}}
          </div>
          <div class="examinationContName">
            <span>剩余储备时间</span>
            <label>{{Number(forms.retiredAge) - Number(forms.inputAge)}}年</label>
          </div>
          <font>（按{{forms.retiredAge}}岁退休）</font>
          <div class="examinationContText">
            建议{{texts}}进行养老资金储备
          </div>
          <div class="examinationContName">
            <span>预期养老地区</span>
            <label>{{forms.expectRegion.split('-')[0]}}</label>
          </div>
          <div class="examinationContText" v-if="forms.expectRegion.split('-')[0]!='中国港澳台地区'">
            根据国家统计局数据，{{detial.textYear}}年该地人均消费支出为{{detial.textConsume}}元
          </div>
          <div class="examinationContName">
            <span>养老资金来源</span><br />
            <label style="padding-top: .5rem;">{{detial.izPerson=='Y'?'个人':''}}<b
                v-if="detial.izPerson=='Y'&&forms.isSocialsecurity=='Y'">+</b>{{forms.isSocialsecurity=='Y'?'社保':''}}<b
                v-if="forms.isSocialsecurity=='Y'&&forms.pensionMethod=='childrenAssisted'">+</b>{{forms.pensionMethod=='childrenAssisted'?'子女':''}}</label>
          </div>
          <div class="examinationContText">
            {{detial.textAnnuityRate}}<img src="@/assets/img/pop.png"
          @click="popText = popText4;tipShow=true" class="popImg"/>
          </div>
        </div>
      </div>
      <!-- <div class="small"><i></i>
        养老压力指数
      </div> -->
      <!-- <div class="content">
        经过综合评估，您的养老画像为：
        <span v-if="leida.length>0">
          <font v-if="leida.indexOf(detial.yuQiYangLao)>-1"><span class="colorRed1">预期养老生活层次</span>品质较高、</font>
          <font v-if="leida.indexOf(detial.tongZhangShuiPing)>-1"><span class="colorRed1">养老地消费水平</span>位于全国前列、</font>
          <font v-if="leida.indexOf(detial.chuBeiShiJian)>-1">剩余<span class="colorRed1">储备时间</span>较短、</font>
          <font v-if="leida.indexOf(detial.sheBaoWaiZiYang)>-1">退休后养老<span class="colorRed1">资金来源</span>较集中、</font>
          <font v-if="leida.indexOf(detial.yangLaoChuBei)>-1">当前<span class="colorRed1">已有养老储备</span>较低、</font>
        </span>
        需要做好养老资金储备与规划。
      </div> -->
      <!-- <two class="echartsBox" style="border-bottom:0 ;margin-bottom: 0;" id="echartss2"
        :datas="{one:detial.yuQiYangLao,two:detial.tongZhangShuiPing,three:detial.chuBeiShiJian,four:detial.sheBaoWaiZiYang,five:detial.yangLaoChuBei}">
      </two> -->


    </div>
    <div class="reportBox">
      <div class="reportTitle" id="maodian">
        <div class="reportTitlleImg">
          <!-- <p>Step2</p> -->
          <img src="@/assets/img/icon2.png" />
        </div>
        <span>第二步<label>|</label>定需求<br />退休后理想养老资金</span>
      </div>
      <div class="echartsTab">
        <div class="echartsTabHead">
          <p :class="{'on':echartsTabIndex=='0'}" @click="echartsTabIndex='0'">活力老人期</p>
          <p :class="{'on':echartsTabIndex=='1'}" @click="echartsTabIndex='1'">银发老人期</p>
        </div>

        <div class="echartsTabBody">
          <div class="content" v-if="echartsTabIndex=='0'">
            综合考虑您的养老目标、未来收入曲线和通胀水平，{{detial.retiredAge}}岁起您每月的理想养老资金约为{{((detial.socialsecurityPaid[detial.retiredAge]+detial.offspringPaid[detial.retiredAge]+detial.personNeed[detial.retiredAge])/12*10000).toFixed(0)}}元，
            <font v-if="(detial.personNeed[detial.retiredAge]/12*10000).toFixed(0)>0">其中需从个人储备中支出约<span
                class="colorRed">{{(detial.personNeed[detial.retiredAge]/12*10000).toFixed(0)}}<label>元</label></span>，
            </font>
            以覆盖您退休后的各项养老支出需求。
          </div>
          <div class="content" v-else-if="echartsTabIndex=='1'">
            80岁进入高龄护理期后，综合考虑医疗护理成本和未来医疗通胀水平，您每月的理想养老资金约为{{((detial.socialsecurityPaid[80]+detial.offspringPaid[80]+detial.personNeed[80])/12*10000).toFixed(0)}}元
            <font v-if="(detial.personNeed[80]/12*10000).toFixed(0)>0">，其中需从个人储备中支出约<span
                class="colorRed">{{(detial.personNeed[80]/12*10000).toFixed(0)}}<label>元</label></span></font>。
          </div>
          <div class="labels" v-if="echartsTabIndex=='0'" ref="echartss4">
            <p>衣食住行</p>
            <p>休闲娱乐</p>
            <p>健康保健</p>
          </div>
          <div class="labels" v-else-if="echartsTabIndex=='1'" ref="echartss5">
            <p>长期护理</p>
            <p>医疗支出</p>
            <p>基本生活
            </p>
          </div>
          <four class="echartsBox" style="border-bottom:0;"
            :datas="{age:detial.retiredAge,one:detial.socialsecurityPaid,two:detial.offspringPaid,three:detial.personNeed,four:detial.liveRate}"
            v-if="echartsTabIndex=='0'">
          </four>
          <five class="echartsBox" style="border-bottom:0;"
            :datas="{age:80,one:detial.socialsecurityPaid,two:detial.offspringPaid,three:detial.personNeed,four:detial.liveRate}"
            v-else-if="echartsTabIndex=='1'">
          </five>
        </div>
      </div>
    </div>
    <div class="reportBox">
      <div class="reportTitle">
        <div class="reportTitlleImg">
          <!-- <p>Step3</p> -->
          <img src="@/assets/img/icon4.png" />
        </div>
        <span>第三步<label>|</label>测缺口<br />退休时理想养老储备</span>
      </div>
      <div ref="echartss3"></div>
      <!-- <div class="small"><i></i>
        退休时理想养老资金
      </div> -->
      <div class="content" v-if="detial.personNeedPv>0">
        根据退休后理想养老资金水平，基于精算原理测算<img src="@/assets/img/pop.png"
          @click="popText = popText1;tipShow=true" />，您在退休时至少需储备<span class="colorRed">
          <countTo :startVal='startVal' :endVal="endVal" :duration='duration' ref="CountToPower" id="CountToPower">
          </countTo>
          <label>万元</label>
        </span>养老资金，以实现<span
          class="colorRed">{{detial.pensionStyle=='basic'?'经济型':detial.pensionStyle=='comfort'?'舒适型':'轻奢型'}}</span>养老目标。
      </div>
      <div class="content" v-else>
        根据退休后理想养老资金水平,基于精算原理测算<img src="@/assets/img/pop.png"
          @click="popText = popText1;tipShow=true" />，您选择的养老资金来源能够支撑<span
          class="colorRed">{{detial.pensionStyle=='basic'?'经济型':detial.pensionStyle=='comfort'?'舒适型':'轻奢型'}}</span>养老目标。
        <countTo :startVal='startVal' :endVal="endVal" :duration='duration' ref="CountToPower" id="CountToPower"
          v-show="false"></countTo>
      </div>
      <three class="echartsBox" style="border-bottom:0 ;margin-bottom: 0;"
        :datas="{one:detial.socialSecurityPaidPv,two:detial.offspringPaidPv,three:detial.personNeedPv}"></three>
      <!-- <div class="small"><i></i>
        退休后预期养老支出
      </div> -->

    </div>
    <div class="reportBox">
      <div class="reportTitle" id="maodian">
        <div class="reportTitlleImg">
          <!-- <p>Step4</p> -->
          <img src="@/assets/img/icon3.png" />
        </div>
        <span>第四步<label>|</label>做规划<br />退休前养老资金储备规划</span>
      </div>
      <div class="small"><i></i>
        月收入分配方案
      </div>
      <div class="content" ref="fadeOut">
        <div v-if="yueshouru">
          <div v-if="detial.sumPercentage.toFixed(1)<5">
            根据模型为您测算的专属收入分配方案，您每月需投入养老储备的比例较小，建议您将每月收入的<span class="colorRed">5<label>%</label></span><img
              src="@/assets/img/pop.png" @click="popText = popText3;tipShow=true" />用于养老储备，防患于未然。
          </div>
          <div v-else-if="detial.sumPercentage>45">
            根据模型为您测算的专属收入分配方案，您每月需投入养老储备的比例较高，建议您至少将每月收入的<span class="colorRed">45<label>%</label></span><img
              src="@/assets/img/pop.png" @click="popText = popText3;tipShow=true" />用于养老储备。
          </div>
          <div v-else>
            根据模型为您测算的专属收入分配方案，建议您将每月收入的<span
              class="colorRed">{{detial.sumPercentage.toFixed(1)}}<label>%</label></span><img src="@/assets/img/pop.png"
              @click="popText = popText3;tipShow=true" />用于养老储备。
          </div>
        </div>
        <div v-else>
          <div v-if="detial.sumPercentage.toFixed(1)<5">
            根据模型为您测算的专属收入分配方案，您每月需投入养老储备的比例较小，建议您将每月收入的<span class="colorRed">5<label>%</label></span><img
              src="@/assets/img/pop.png" @click="popText = popText3;tipShow=true" />用于养老储备，防患于未然。
          </div>
          <div v-else-if="detial.sumPercentage>25">
            根据模型为您测算的专属收入分配方案，您每月需投入养老储备的比例较高，建议您至少将每月收入的<span class="colorRed">25<label>%</label></span><img
              src="@/assets/img/pop.png" @click="popText = popText3;tipShow=true" />用于养老储备。
          </div>
          <div v-else>
            根据模型为您测算的专属收入分配方案，建议您将每月收入的<span
              class="colorRed">{{detial.sumPercentage.toFixed(1)}}<label>%</label></span><img src="@/assets/img/pop.png"
              @click="popText = popText3;tipShow=true" />用于养老储备。
          </div>
        </div>
      </div>
      <programme :datas="{one:detial.sumPercentage,two:detial.insurancePercentage,yueshourus:yueshouru}">
      </programme>
      <!-- one:detial.sumPercentage.toFixed(1) -->
      <div class="small" ref="echartss6"><i></i>
        养老资金储备积累
      </div>
      <links
        :datas="{monthMonet:yueshouru,one:detial.sumPercentage,two:detial.insuranceIncomeAccumulatedShow,three:detial.investmentIncomeAccumulatedShow,four:detial.depositIncomeAccumulatedShow,pensionStyle:detial.pensionStyle,personNeedPv:detial.personNeedPv,monthIncomeSection:detial.monthIncomeSection,five:detial.insuranceAlreadyAccumulated,six:detial.depositAlreadyAccumulated,seven:detial.investmentAlreadyAccumulated,amountNum:detial.amountExistingSection}">
      </links>
      <!-- <p class="dotted"></p> -->
      <div class="repotrTab">
        <div class="repotrTabBox">
          <div class="repotrTabTitle">
            <img src="../../assets/img/shou.png" />
            <span>点击更改预期养老生活，查看相应养老规划方案</span>
          </div>
          <div class="repotrTabs">
            <p :class="{'on':tabIndex==0}" @click="onTab(0)">经济型</p>
            <p :class="{'on':tabIndex==1}" @click="onTab(1)">舒适型</p>
            <p :class="{'on':tabIndex==2}" @click="onTab(2)">轻奢型</p>
          </div>
        </div>
      </div>

      <div class="small"><i></i>
        同类客群养老储备排行榜
      </div>
      <div class="content" ref="echartss1">
        经过大数据聚类模型分析，共匹配到<span class="colorRed">{{detial.similarNum}}<label>位</label></span>和您相似的客户。其中，排名第10%的客户已储备<span
          class="colorRed">{{Number(detial.similarPensionTop10 / 10000).toFixed(0)
      }}<label>万元</label></span>。
      </div>
      <one class="echartsBox"
        :datas="{one:detial.similarPensionTop10,two:detial.similarPensionTop30,three:detial.similarPensionTop50}"></one>
      <div class="small"><i></i>
        家庭生命周期保障配置
      </div>
      <div class="content">
        养老储备不是单打独斗，全面完备的家庭保障是幸福养老的良好开端。根据家庭所处生命周期动态配置保险，有助于最大化发挥家庭保障效用。
      </div>
      <tables v-if="tabelShow"></tables>
      <tabless v-if="tabelShow"></tabless>
    </div>
    <img src="@/assets/img/toPathImg.png" @click="onToShop(3)" class="toPathShop1"/>
    <img src="@/assets/img/toPathImg.jpg" @click="onToShop(2)" class="toPathShop"/>
    <div class="share" @click="handleShareWechat">
      <img src="@/assets/img/share.png" />
      <p>转发邀请好友测评</p>
    </div>
    <p class="reportTip">*以上测算结果仅供参考，不作为投保依据，请您根据个人情况审慎决定
    </p>
    <!-- 弹窗 -->
    <van-overlay :show="tipShow" @click="tipShow = false">
      <pops :texts="popText" @closePop="tipShow = false"></pops>
    </van-overlay>
    <!-- 弹窗 -->
    <van-overlay :show="shareShow" @click="shareShow = false" :z-index="5">
      <div @click.stop="shareShow = false" class="shareBg">
        <img src="@/assets/img/shareBg.png" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
  import one from './components/one.vue'
  // import two from './components/two.vue'
  import three from './components/three.vue'
  import four from './components/four.vue'
  import five from './components/five.vue'
  import programme from './components/programme.vue'
  import tables from './components/tables.vue'
  import tabless from './components/tabless.vue'
  import links from './components/links.vue'
  import pops from '@/components/pops.vue'
  import api from '@/api/index.js'

  import countTo from 'vue-count-to';
  export default {
    components: {
      one,
      // two,
      three,
      four,
      five,
      programme,
      tables,
      tabless,
      links,
      pops,
      countTo
    },
    data() {
      return {
        detial: null,
        // leida: [],
        tabIndex: 0, //  0 经济型 1 舒适型 2 轻奢行
        echartsTabIndex: '0',
        tipShow: false,
        popText: '',
        popText1: '综合考虑未来支出现金流、财富增值、生存概率等因素，使用无风险利率贴现至退休时点。',
        popText2: '随着物质条件不断丰富、宏观经济不断发展，人的养老支出也在不断增长。退休后的收入至少需要跑赢通胀，才足够抵挡长寿风险。',
        popText3: '养老储备是一场持久战，需要日积月累。若当前每月养老投入压力过大，可以根据实际情况自主灵活投入，前期少投、后期补足，保持动态平衡。',
        popText4: '养老金替代率是指个人劳动者退休后一年养老资金收入与退休前一年收入之间的比率。',
        forms: {},
        shareIcon: require('@/assets/img/share.jpg'),
        shareShow: false,
        yueshouru: false,
        oldScrollTop: null,
        startVal: 0,
        endVal: 10,
        duration: 2000, // 持续时间
        tabelShow: true,
        texts: ''
      }
    },
    mounted() {
      this.forms = JSON.parse(localStorage.getItem("formData"));
      this.detial = JSON.parse(localStorage.getItem("reportData"));
      this.texts = (this.forms.inputAge < 40) ? '定期' : '及时'
      this.dingwei(this.forms.inputAge)
      this.endVal = Number(this.detial.personNeedPv)
      //leixing
      if (this.forms.pensionStyle == 'basic') {
        this.tabIndex = 0
      } else if (this.forms.pensionStyle == 'comfort') {
        this.tabIndex = 1
      } else if (this.forms.pensionStyle == 'luxury') {
        this.tabIndex = 2
      }
      // yueshouru
      if (this.forms.monthIncome == '3千以下' || this.forms.monthIncome == '3千-6千' || this.forms.monthIncome == '6千-1万') {
        this.yueshouru = false
      } else {
        this.yueshouru = true
      }
      let that = this;
      // that.$nextTick(function() {
        // 监听页面滚动事件
        window.addEventListener("scroll", that.scrolling, true);
      // })
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrolling);
    },
    methods: {
     async onToShop(types){
        // console.log(1)
        let res = await api.getToPath({id:types});
        // console.log(res)
        if (res.code == 200) {
          if(types==2){//2国喜保 中国人寿保险商城
            window.location.href='https://group.e-chinalife.com/o2s/o2s-mall-web/pages/wxmpJumpMgt/Index?Id=7FECC9A8563E48FD898E776566491D8E';
          }else if(types==3){//3国寿福享E盈
             window.location.href='https://weixin.e-chinalife.com/mic/oauth?officialid=gh_b47c5b804348&secret=&sub=false&wsp_param=openid,jsapi,unionid&linkUrl=https%3A%2F%2Fgroup.e-chinalife.com%2Fo2s%2Fo2s-exclusivebusiness-sale%2Fweb%2FinitExcWeXinOuth%3FproductCode%3DP000000104125%26linkCode%3D4125009900030000566';
          }

        }
      },
      //滚动条切换
      scrolling(e) {
        let that = this;

        // that.$nextTick(function() {
        // 滚动条距文档顶部的距离
        // let scrollTop = e.target.scrollTop || document.documentElement.scrollTop;
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        // const echartss2 = document.getElementById("echartss2").offsetTop;

        // if (scrollTop < (echartss2 - 146) && scrollTop > (echartss2 - 196)) {
        //   that.$store.commit('setEchartsTwo');
        // }
        // console.log('scrollTop',scrollTop)
        if (that.echartsTabIndex == '0') {
          // const echartss4 = document.getElementById("echartss4").offsetTop;
          const echartss4Top = that.$refs.echartss4.offsetTop;

          if (scrollTop < (echartss4Top - 375) && scrollTop > (echartss4Top - 425)) {
            that.$store.commit('setEchartsFour');
          }
        } else if (that.echartsTabIndex == '1') {
          const echartss5Top = that.$refs.echartss5.offsetTop;
          if (scrollTop < (echartss5Top - 375) && scrollTop > (echartss5Top - 425)) {
            that.$store.commit('setEchartsFive');
          }
        }
        if (that.$refs.echartss3) {
          const echartss3Top = that.$refs.echartss3.offsetTop;
          if (scrollTop < (echartss3Top - 279) && scrollTop > (echartss3Top - 329)) {
            that.$store.commit('setEchartsThree');
            //文字加载
            that.endVal = 0;
            setTimeout(function() {
              that.endVal = Number(that.detial.personNeedPv)
            }, 200)
          }
        }
        if (that.$refs.echartss1) {
          const echartss1Top = that.$refs.echartss1.offsetTop;
          if (scrollTop < (echartss1Top - 296) && scrollTop > (echartss1Top - 346)) {
            that.$store.commit('setEchartsOne');
          }
        }
        if (that.$refs.echartss6) {
          const echartss6Top = that.$refs.echartss6.offsetTop;
          if (scrollTop < (echartss6Top - 270) && scrollTop > (echartss6Top - 380)) {
            that.$store.commit('setEchartsSex');
          }
        }
        if (that.$refs.fadeOut) {
          // 淡出动画
          const fadeOutTop = that.$refs.fadeOut.offsetTop;
          if (scrollTop < (fadeOutTop - 296) && scrollTop > (fadeOutTop - 346)) {
            that.$store.commit('setFadeOut');
          }
        }
        // })
      },
      dingwei(age) {
        console.log('age', age)
        let that = this;
        that.$nextTick(function() {
          let indexVal = 0;
          if (age < 6) {
            indexVal = 0;
          } else if (age < 18) {
            indexVal = 1;
          } else if (age < 25) {
            indexVal = 2;
          } else if (age < 32) {
            indexVal = 3;
          } else if (age < 45) {
            indexVal = 4;
          } else if (age < 60) {
            indexVal = 5;
          } else {
            indexVal = 6;
          }
          that.$store.dispatch('setReportTabIndex', indexVal);
          that.$store.dispatch('setReportTabIndexs', indexVal);
        })
      },
      //获取数据
      async getDetials(index) {
        if (index == 0) {
          this.forms.pensionStyle = 'basic'
        } else if (index == 1) {
          this.forms.pensionStyle = 'comfort'
        } else if (index == 2) {
          this.forms.pensionStyle = 'luxury'
        }
        let res = await api.getInfo(this.forms);

        if (res.code == 200) {
          this.detial = res.data;
          console.log(this.bubbleSort([this.detial.yuQiYangLao, this.detial.tongZhangShuiPing, this.detial
            .chuBeiShiJian, this.detial.sheBaoWaiZiYang, this.detial.yangLaoChuBei
          ]))
          // this.leida = this.bubbleSort([this.detial.yuQiYangLao, this.detial.tongZhangShuiPing, this.detial
          //   .chuBeiShiJian, this.detial.sheBaoWaiZiYang, this.detial.yangLaoChuBei
          // ]).slice(2);
          if (this.detial.pensionStyle == 'basic') {
            this.tabIndex = 0
          } else if (this.detial.pensionStyle == 'comfort') {
            this.tabIndex = 1
          } else if (this.detial.pensionStyle == 'luxury') {
            this.tabIndex = 2
          }

        } else {
          this.$toast(res.msg);
          return false
        }
      },
      // 数组排序
      bubbleSort(arr) {
        for (var i = 0; i < arr.length - 1; i++) {
          for (var j = 0; j < arr.length - i; j++) {
            if (arr[j] > arr[j + 1]) {
              // 交换两个数的位置
              var temp = 0;
              temp = arr[j];
              arr[j] = arr[j + 1];
              arr[j + 1] = temp;
            }
          }
        }
        return arr;
      },
      //切换
      onTab(index) {
        this.tabIndex = index;
        this.getDetials(index)
        const tabsId = document.querySelector('#maodian');
        this.$store.commit('setFadeOut1');
        // this.tabelShow=false;
        // 判断进行平滑滚动
        if (tabsId) {
          window.scrollTo({
            top: tabsId.offsetTop - 35,
            behavior: "smooth",
          });
        }
      },
      //分享
      handleShareWechat() {
        let that = this;
        that.shareShow = true;
        // import('@/api/wechat.js').then(mod => {
        //   let wx = mod.default
        //   wx.init()
        //   //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
        //   wx.setUpdateTimelineShareData()
        //   //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
        //   wx.setUpdateAppMessageShareData()
        // })
      },
      //提示弹窗
      toTip() {
        this.$dialog.alert({
          message: '综合考虑未来支出现金流、财富增值、生存概率等因素，使用无风险利率贴现至退休时点。',
        });
      },
      //提示弹窗
      toTip1() {
        this.$dialog.alert({
          message: '随着物质条件不断丰富、宏观经济不断发展，人的养老支出也在不断增长。退休后的收入至少需要跑赢通胀，才足够抵挡长寿风险。',
        });
      },
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrolling);
    },

  }
</script>
<style scoped>
  @import url("../../assets/style/report.css");
</style>
