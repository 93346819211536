import axios from "axios";  // 引入 axios 函数
// let httpUrl = 'http://cesuanapp.mynatapp.cc';
let httpUrl = 'https://actuary.bfstor.com/prod-api';
// let httpUrl = 'http://182.92.161.203:5548'
// let token = localStorage.getItem('token')==null?'':localStorage.getItem('token');
// 创建 axios 实例
const service = axios.create({
  baseURL: httpUrl, //请求地址
  // withCredentials: true, // 跨域请求时是否传递 cookie
   headers: {
     'Access-Control-Allow-Origin': '*',
   //   'Access-Control-Allow-Methods': 'POST,GET,PUT,DELETE',
   //   'Access-Control-Max-Age': '3600',
   //   'Access-Control-Allow-Headers': '*',
   //   'Access-Control-Allow-Credentials': 'true',
    },
  timeout: 3000 // 请求超时时间
});

// 在发送请求时的请求头处理函数
service.interceptors.request.use(
  config => {
     // 在此处对 config 进行处理，即发送请求前的操作
     // 例如
	 // if(token){
		//   config.headers['authKey'] = token; // 在请求头中添加 authKey 属性并赋值
	 // }

     return config;
  },
  error => {
    // 请求失败时的处理
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// 在返回数据时的响应头处理函数
service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code !== 0) { // 成功的 code 码一般是 200，当然 code 字段不一定存在，这里处理请求失败的操作
      // 可以使用提示框进行消息提示
      // 例如使用 alert
      // alert(res.msg); // msg 为后台返回的错误信息
      // 在这里还可以进行登录权限的校验
      if (res.code === 200) {
		   return res;
        // 用户无权限可以进行进一步操作，比如超时退出等等
      }
      return Promise.reject(new Error(res.msg || 'Error'));
    } else {
      // 返回正常的请求数据
      return res;
    }
  },
  error => {
    // 错误处理函数
    console.log('err' + error); // for debug
    return Promise.reject(error);
  }
);

// 最后将 service 进行导出
export default service;
