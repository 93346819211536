<template>
  <div class="formBox headerBg1 headerBg2">
    <div class="headers headers1">
      <img src="@/assets/img/left.png" @click="$router.go(-1)">
      <span>精算养老规划助手</span>
      <img src="@/assets/img/headBg2.png" class="niu">
    </div>

    <div class="homeBox">
      <div class="Progress">
        <p>结果测算中,请稍等</p>
        <div class="ProgressBox">
          <el-progress type="line" :show-text="false" :stroke-width="20" :percentage="progressVal"></el-progress>
        </div>
      </div>
      <div class="homeCont">
        <div class="homeContText">
          <label>正在进行</label>
          <div class="wavy" v-if="ballIndex==1">
            <span style="--i:1;">养</span>
            <span style="--i:2;">老</span>
            <span style="--i:3;">缺</span>
            <span style="--i:4;">口</span>
            <span style="--i:5;">测</span>
            <span style="--i:6;">算</span>
           <!-- <span style="--i:7;">.</span>
            <span style="--i:8;">.</span>
            <span style="--i:9;">.</span> -->
          </div>
          <div class="wavy wavy1" v-else-if="ballIndex==2">
            <span style="--i:1;">养</span>
            <span style="--i:2;">老</span>
            <span style="--i:3;">资</span>
            <span style="--i:4;">产</span>
            <span style="--i:5;">规</span>
            <span style="--i:6;">划</span>
           <!-- <span style="--i:7;">.</span>
            <span style="--i:8;">.</span>
            <span style="--i:9;">.</span> -->
          </div>
          <div class="wavy" v-else>
            <span style="--i:1;">同</span>
            <span style="--i:2;">类</span>
            <span style="--i:3;">客</span>
            <span style="--i:4;">群</span>
            <span style="--i:5;">匹</span>
            <span style="--i:6;">配</span>
            <!-- <span style="--i:7;">.</span>
            <span style="--i:8;">.</span>
            <span style="--i:9;">.</span> -->
          </div>
        </div>
        <div class="an" ref="an">
          <canvas class="videos" id="video1" :style="{opacity: isLoaded1 ? 1 : 0}"></canvas>
          <canvas class="videos videoss" id="video2" :style="{opacity: isLoaded2 ? 1 : 0}"></canvas>
          <canvas class="videos videoss" id="video3" :style="{opacity: isLoaded3 ? 1 : 0}"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        progressVal: 0,
        ballIndex: 1,
        player1: null,
        isLoaded1: false,
        player2: null,
        isLoaded2: false,
        player3: null,
        isLoaded3: false,
        interval: null,
        resd:true
      }
    },
    mounted() {
      console.log(this.$refs.an.offsetWidth)
      this.$refs.an.style.height= this.$refs.an.offsetWidth+"px";
      let that = this;
      // 视频动画加载
      that.player1 = new JSMpeg.Player("./gif1.ts", {
        canvas: document.getElementById('video1'),
        autoplay: true,
        loop: false,
        audio: false,
        onSourceCompleted: function() { //动画加载完成
          if (!that.isLoaded1) {
            that.isLoaded1 = true;
            that.ballIndex = 1
          }
        },
        onPlay: function() { //动画播放
          that.dijia()
          that.player2 = new JSMpeg.Player("./gif2.ts", {
            canvas: document.getElementById('video2'),
            autoplay: false,
            loop: false,
            audio: false,
            onSourceCompleted: function() { //动画加载完成
              if (!that.isLoaded2) {
                that.isLoaded2 = true;
                that.ballIndex = 2
              }
            },
            onPlay: function() { //动画播放
              that.dijia()
              that.player3 = new JSMpeg.Player("./gif3.ts", {
                canvas: document.getElementById('video3'),
                autoplay: false,
                loop: false,
                audio: false,
                onSourceCompleted: function() { //动画加载完成
                  if (!that.isLoaded3) {
                    that.isLoaded3 = true;
                    that.ballIndex = 3
                  }
                },
                onPlay: function() { //动画播放
                  that.dijia()
                },
                onEnded: function() { //动画结束
                if(that.resd){
                   that.toResult()
                }

                }
              })
            },
            onEnded: function() { //动画结束
              that.isLoaded2 = false;
              that.ballIndex = 3

              that.player3.play()
            }
          })
        },
        onEnded: function() { //动画结束
          that.isLoaded1 = false;
          that.player2.play()
        }
      })
      that.player1.play()
    },
    beforeDestroy() {
     this.resd=false
    },
    methods: {
      dijia() {
        let that = this;
        //每隔1秒执行一次
        that.interval = setInterval(function() {
          that.progressVal = that.progressVal + 13
        }, 1100);
        // 持续3秒
        setTimeout(() => clearInterval(that.interval), 3300)
      },
      //跳转结果页
      toResult() {
        this.$router.push({
          path: '/report',
          query: {
            type: 1
          }
        });
      }
    }
  }
</script>
<style scoped>
  @import url("../../assets/style/public.css");
  @import url("../../assets/style/home.css");

  /deep/.el-progress-bar__inner {
    background: linear-gradient(180deg, #FB8F74 0%, #F84417 100%)
  }

  .videos {
    width: 100%;
    margin: 0 auto;
  }

  .videoss {
    position: absolute;
    left: 0;
    top: 0;
  }
</style>
