<template>
  <div>
    <van-picker title="行业" show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel" />
  </div>
</template>

<script>
  import api from '@/api/index.js'
  export default {
    data() {
      return {
        columns: [],
      }
    },
    mounted() {
      this.detials()
    },
    methods: {
      async detials() {
        let res = await api.getHangyeCode();
        if (res.code == 200) {
          let resData = res.data;
          resData.forEach(function(item) {
            item.text = item.dictLabel
            item.id = item.dictValue
          })
          this.columns = resData
        } else {
          this.$toast(res.msg);
          return false
        }
      },
      onConfirm(val) {
        this.$emit('confirm', val)
      },
      onCancel() {
        this.$emit('confirm', '')
      }
    }
  }
</script>
<style scoped>

</style>
