<template>
  <div>
    <div v-html="text" class="wenben"></div>
    <div id="echart6" ref="main6"></div>
  </div>
</template>
<script>
  export default {
    props: {
      datas: {
        type: Object,
      }
    },
    data() {
      return {
        myChart6: null,
        myChart7: null,
        text: '',
        personNeedPv: 0,
        amountNum: 0,
        // interval: null,
        // tipShow: false,
        // popText: ''
      };
    },
    watch: {
      datas: {
        handler(newVal, oldVal) {
          // console.log(newVal)
          this.personNeedPv = newVal.personNeedPv;
          this.amountNum = newVal.amountNum;
          // console.log('personNeedPv2', this.personNeedPv)
          if (newVal.personNeedPv > 0 && newVal.personNeedPv > newVal.amountNum) {
            this.getEcharts6_1(newVal);
          } else {
            this.getEcharts6_2(newVal);
          }
        },
        deep: true
      },
      '$store.state.echartsSex': {
        handler(newVal, oldVal) {
          // console.log('personNeedPv3', this.datas.personNeedPv)
          if (this.datas.personNeedPv > 0 && this.datas.personNeedPv > this.datas.amountNum) {
            this.getEcharts6_1(this.datas);
          } else {
            this.getEcharts6_2(this.datas);
          }
        },
        deep: true
      },
    },
    methods: {
      getEcharts6_2(val) {
        let that = this;
        // that.forms = JSON.parse(localStorage.getItem("formData"));
        // let ages = that.forms.inputAge; //选择年龄
        // let retiredAge = that.forms.retiredAge; //退休年龄
        // amountNum客户输入的已有养老储备     personNeedPv个人储备

        if (val.personNeedPv == 0) {
          that.text = `养老储备越早越好，按照上述月收入分配方案“小步早走”持续进行养老资金储备，能够更好助力应对长寿风险。`
        } else if (val.personNeedPv < val.amountNum || val.personNeedPv == val.amountNum) {
          that.text = `充足的养老储备能够更好地应对长寿风险，建议您在现有储备基础上，按照上述月收入分配方案进一步巩固养老资金储备。`
        }
        // x轴年龄
        let dataX = ['1年后', '5年后', '10年后', '15年后'];
        // 撒湖南工业养老保险累计
        let dataArry1 = [{
            value: 3.96344,
            symbol: 'image://' + require('@/assets/img/ren.png'),
            symbol: 'image://' + require('@/assets/img/ren.png'),
            symbolSize: [23, 32],
            // symbolKeepAspect: true,
            symbolOffset: [0, -15],
          },
          {
            value: 24.35919,
            symbol: 'image://' + require('@/assets/img/qi.png'),
            symbolSize: 23,
            symbolKeepAspect: true,
            symbolOffset: [1, -10],
          },
          {
            value: 59.21911,
            symbol: 'image://' + require('@/assets/img/qi.png'),
            symbolSize: 23,
            symbolKeepAspect: true,
            symbolOffset: [1, -10],
          },
          {
            value: 105.93435,
            symbol: 'image://' + require('@/assets/img/qi.png'),
            symbolSize: 23,
            symbolKeepAspect: true,
            symbolOffset: [1, -10],
          },
        ];
        //其他仰赖储蓄累积
        let dataArry2 = [2.64230,
          16.23946,
          39.47940,
          70.62290,
        ];
        //客户月收入 单位元
        let flags1 = ((3.185481 * val.monthIncomeSection) / 10000).toFixed(1),
          flags2 = ((6.878328 * val.monthIncomeSection) / 10000).toFixed(1),
          flags3 = ((11.159348 * val.monthIncomeSection) / 10000).toFixed(1);
        // console.log('dataArry1', dataArry1)
        // console.log('dataArry2', dataArry2)
        // console.log('dataArry3', dataArry3)
        let seriesArry = [],
          legendData = [];
        seriesArry.push({
          name: '商业养老保险累积',
          type: 'line',
          smooth: true,
          symbolSize: 0,
          label: {
            show: true,
            position: 'top',
            formatter(param) {
              // console.log('param', param.name)
              let val = '';
              if (param.name == '5年后') {
                val = `${flags1}万元`;
              } else if (param.name == '10年后') {
                val = `${flags2}万元`;
              } else if (param.name == '15年后') {
                val = `${flags3}万元`;
              }
              return val
            },
            fontSize: 12,
            fontWeight: 'bold',
            color: '#FA6946',
            offset: [-45, 25],
          },
          data: dataArry1,
          areaStyle: {
            opacity: 1,
            color: '#FF7D5D',
          },
          lineStyle: {
            width: 0
          },
          color: '#FF7D5D',
          z: 1
        })
        seriesArry.push({
          name: '其他养老储蓄累积',
          type: 'line',
          smooth: true,
          symbol: 'none',
          data: dataArry2,
          areaStyle: {
            opacity: 1,
            color: '#FEDBD2',
          },
          lineStyle: {
            width: 0
          },
          color: '#FEDBD2',
          z: 2
        })
        legendData.push('商业养老保险累积')
        legendData.push('其他养老储蓄累积')
        // let dataZoom = [];
        // if (dataX.length > 10) {
        //   dataZoom = [{
        //     type: 'slider',
        //     filterMode: 'none',
        //     startValu: 0,
        //     endValue: 10,
        //     bottom: '17%',
        //     zoomLock: true,
        //     zoomOnMouseWheel: false,
        //     height: 0,
        //     borderColor: "transparent",
        //     moveHandleSize: 10,
        //     moveHandleStyle: {
        //       color: "#D5D5D7",
        //     },
        //     emphasis: {
        //       moveHandleStyle: {
        //         color: "#D5D5D7",
        //       }
        //     },
        //     textStyle: {
        //       color: "transparent"
        //     },
        //     showDataShadow: false,
        //     showDetail: false
        //   }]
        // }
        let option = {
          grid: {
            top: 40,
            bottom: 65,
            left: 30,
            right: 30
          },
          legend: {
            selectedMode: false, //取消图例上的点击事件
            left: 'center',
            icon: 'roundRect',
            bottom: 6,
            data: legendData,
            textStyle: {
              color: "#31394D",
              fontSize: 12
            },
            itemHeight: 6,
            itemWidth: 10
          },
          xAxis: [{
            // name:'年龄(岁)',
            // nameGap: 2 ,
            // nameTextStyle: {
            //   color: "#D5D5D7",
            //    fontSize: 12,
            //    verticalAlign: "top",
            //          lineHeight: 0
            //   // padding:[0,0,0,-10]
            // },
            // type: 'category',
            boundaryGap: false,
            data: dataX,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D5D5D7"
              }
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: "#D5D5D7"
              }
            },
            axisLabel: {
              textStyle: {
                color: "#828286",
                fontSize: 13
              },
            },
          }],
          yAxis: [{
            type: 'value',
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            }
          }, ],
          // dataZoom: dataZoom,
          series: seriesArry
        }
        that.myChart6.clear();
        that.myChart6.setOption(option);
      },
      getEcharts6_1(val) {
        let that = this;
        that.forms = JSON.parse(localStorage.getItem("formData"));
        let ages = Number(that.forms.inputAge); //选择年龄
        let retiredAge = Number(that.forms.retiredAge); //退休年龄

        //其他养老储备累积   three+four+six+seven
        let linkArry2 = val.three.map(function(item, index) {
          return item + val.four[index] + val.six[index] + val.seven[index];
        });
        //商业养老保险累积 其他养老储备累积+two+five
        let linkArry1 = linkArry2.map(function(item, index) {
          return item + val.two[index] + val.five[index];
        });
        // console.log('linkArry1', linkArry1)
        // console.log('linkArry2', linkArry2)
        //提示文字
        let texts = '';
        if (that.datas.pensionStyle == 'basic') {
          texts = '经济型';
        } else if (that.datas.pensionStyle == 'comfort') {
          texts = '舒适型';
        } else if (that.datas.pensionStyle == 'luxury') {
          texts = '轻奢型';
        }
        // x轴年龄
        let dataX = [];
        // 三条线数据
        let dataArry1 = [];
        let dataArry2 = [];
        let dataArry3 = [];
        //红旗
        let flag1 = null,
          flag2 = null,
          flag3 = null;
        let xAxis = [];
        // console.log('monthMonet', val.monthMonet)
        // console.log('one', val.one)
        if (val.monthMonet) { //月收入大于1万
         if (val.one < 5) { //低于5%  Floor情景
            that.text = `若按照上述月收入分配方案持续进行养老资金储备，预计您在退休前可以完成养老储备目标，实现<span>${texts}</span>养老生活。`;
            // console.log('retiredAge', retiredAge)
            // console.log('ages', ages)
            let xDataMax = linkArry1.indexOf(val.personNeedPv);
            // console.log('xDataMax', xDataMax)
            if (xDataMax > -1) { //有查到结果
              dataArry1 = linkArry1 ? linkArry1.slice(ages, xDataMax + 1) : [];
              dataArry2 = linkArry2 ? linkArry2.slice(ages, xDataMax + 1) : [];
              for (let i = ages; i < xDataMax + 1; i++) {
                xAxis.push(i);
              }
              //图形位置
              flag1 = ages + Math.round((xDataMax - ages) / 3);
              flag2 = ages + Math.round((xDataMax - ages) / 3 * 2);
              flag3 = xDataMax;
              for (let i = ages; i < xDataMax + 1; i++) {
                if (i == ages || i == flag1 || i == flag2 || i == flag3) {
                  dataX.push(i);
                  dataArry3.push(val.personNeedPv)
                }
              }
            } else {
              let ageMax = [];
              linkArry1.forEach(function(item, index) {
                if (item > val.personNeedPv) {
                  ageMax.push(index);
                }
              })
              let ageMaxs = ageMax[0];
              // console.log('ageMaxs',ageMaxs)
              //小于6
              if (ageMaxs - ages < 6) {
                flag1 = null;
                flag2 = null;
                flag3 = ageMaxs;

                let dataArryTotal1 = linkArry1 ? linkArry1.slice(ages, ageMaxs+1) : [];
                let dataArryTotal2 = linkArry2 ? linkArry2.slice(ages, ageMaxs+1) : [];
                xAxis.push(ages);
                xAxis.push(ageMaxs);
                dataX.push(ages);
                dataX.push(ageMaxs);
                dataArry3.push(val.personNeedPv)
                dataArry3.push(val.personNeedPv)
                dataArryTotal1.forEach(function(item, index) {
                  // console.log('index',index)
                  if (index == 0) {
                    dataArry1.push({
                      value: item,
                      symbol: 'image://' + require('@/assets/img/ren.png'),
                      symbolSize: [23, 32],
                      // symbolKeepAspect: true,
                      symbolOffset: [15, -15],
                    })
                    dataArry2.push(dataArryTotal2[index])
                  } else if (index == (dataArryTotal1.length-1)) {
                    dataArry1.push({
                      value: item,
                      symbol: 'image://' + require('@/assets/img/qi.png'),
                      symbolSize: 23,
                      symbolKeepAspect: true,
                      symbolOffset: [1, -10],
                    })
                    dataArry2.push(dataArryTotal2[index])
                  }
                })
              } else {
                flag1 = ages + Math.round((ageMax[0] - ages) / 3);
                flag2 = ages + Math.round((ageMax[0] - ages) / 3 * 2);
                flag3 = ageMax[0];

                dataArry1 = linkArry1 ? linkArry1.slice(ages, ageMax[0] + 1) : [];
                dataArry2 = linkArry2 ? linkArry2.slice(ages, ageMax[0] + 1) : [];

                for (let i = ages; i < ageMax[0] + 1; i++) {
                  xAxis.push(i);
                  if (i == ages || i == flag1 || i == flag2 || i == flag3) {
                    dataX.push(i);
                    dataArry3.push(val.personNeedPv)
                  }
                }
              }

            }
          } else if (val.one > 45) { //超过45% cap情景
            that.text = `若按照上述月收入分配方案持续进行养老资金储备，您在退休前可以大幅降低养老储备缺口，距离预期养老生活更近一步。
`;
            dataArry3 = null;
            dataArry1 = linkArry1 ? linkArry1.slice(ages, retiredAge + 1) : [];
            dataArry2 = linkArry2 ? linkArry2.slice(ages, retiredAge + 1) : [];
            for (let i = ages; i < retiredAge + 1; i++) {
              xAxis.push(i);
            }
            //图形位置
            flag1 = ages + Math.round((retiredAge - ages) / 3);
            flag2 = ages + Math.round((retiredAge - ages) / 3 * 2);
            flag3 = retiredAge;
            for (let i = ages; i < retiredAge + 1; i++) {
              if (i == ages || i == flag1 || i == flag2 || i == flag3) {
                dataX.push(i);
              }
            }
          } else { //5%-45%  正常情景

            that.text = `若按照上述月收入分配方案持续进行养老资金储备，预计您在退休前可以完成养老储备目标，实现<span>${texts}</span>养老生活。`;
            dataArry1 = linkArry1 ? linkArry1.slice(ages, retiredAge + 1) : [];
            dataArry2 = linkArry2 ? linkArry2.slice(ages, retiredAge + 1) : [];
// console.log('linkArry1',linkArry1)
            for (let i = ages; i < retiredAge + 1; i++) {
              xAxis.push(i);
            }
            //图形位置
            flag1 = ages + Math.round((retiredAge - ages) / 3);
            flag2 = ages + Math.round((retiredAge - ages) / 3 * 2);
            flag3 = (retiredAge);
            for (let i = ages; i < retiredAge + 1; i++) {
              if (i == ages || i == flag1 || i == flag2 || i == flag3) {
                dataX.push(i);
                dataArry3.push(val.personNeedPv)
              }
            }
          }

          // console.log('dataX', dataX)
        } else {
          // console.log('one2', val.one)
          if (val.one < 5) { //低于5%  Floor情景
            that.text = `若按照上述月收入分配方案持续进行养老资金储备，预计您在退休前可以完成养老储备目标，实现<span>${texts}</span>养老生活。`;
            // console.log('retiredAge', retiredAge)
            // console.log('ages', ages)
            let xDataMax = linkArry1.indexOf(val.personNeedPv);
            // console.log('xDataMax', xDataMax)
            if (xDataMax > -1) { //有查到结果
              dataArry1 = linkArry1 ? linkArry1.slice(ages, xDataMax + 1) : [];
              dataArry2 = linkArry2 ? linkArry2.slice(ages, xDataMax + 1) : [];
              for (let i = ages; i < xDataMax + 1; i++) {
                xAxis.push(i);
              }
              //图形位置
              flag1 = ages + Math.round((xDataMax - ages) / 3);
              flag2 = ages + Math.round((xDataMax - ages) / 3 * 2);
              flag3 = xDataMax;
              for (let i = ages; i < xDataMax + 1; i++) {
                if (i == ages || i == flag1 || i == flag2 || i == flag3) {
                  dataX.push(i);
                  dataArry3.push(val.personNeedPv)
                }
              }
            } else {
              let ageMax = [];
              linkArry1.forEach(function(item, index) {
                if (item > val.personNeedPv) {
                  ageMax.push(index);
                }
              })
              let ageMaxs = ageMax[0];
              // console.log('ageMaxs',ageMaxs)
              // console.log('年龄差',ageMaxs - ages)
              //小于3
              if (ageMaxs - ages < 6) {
                flag1 = null;
                flag2 = null;
                flag3 = ageMaxs;

                let dataArryTotal1 = linkArry1 ? linkArry1.slice(ages, ageMaxs+1) : [];
                let dataArryTotal2 = linkArry2 ? linkArry2.slice(ages, ageMaxs+1) : [];
                xAxis.push(ages);
                xAxis.push(ageMaxs);
                dataX.push(ages);
                dataX.push(ageMaxs);
                dataArry3.push(val.personNeedPv)
                dataArry3.push(val.personNeedPv)
                dataArryTotal1.forEach(function(item, index) {
                  // console.log('index',index)
                  if (index == 0) {
                    dataArry1.push({
                      value: item,
                      symbol: 'image://' + require('@/assets/img/ren.png'),
                      symbolSize: [23, 32],
                      // symbolKeepAspect: true,
                      symbolOffset: [15, -15],
                    })
                    dataArry2.push(dataArryTotal2[index])
                  } else if (index == (dataArryTotal1.length-1)) {
                    dataArry1.push({
                      value: item,
                      symbol: 'image://' + require('@/assets/img/qi.png'),
                      symbolSize: 23,
                      symbolKeepAspect: true,
                      symbolOffset: [1, -10],
                    })
                    dataArry2.push(dataArryTotal2[index])
                  }
                })
              } else {
                flag1 = ages + Math.round((ageMax[0] - ages) / 3);
                flag2 = ages + Math.round((ageMax[0] - ages) / 3 * 2);
                flag3 = ageMax[0];

                dataArry1 = linkArry1 ? linkArry1.slice(ages, ageMax[0] + 1) : [];
                dataArry2 = linkArry2 ? linkArry2.slice(ages, ageMax[0] + 1) : [];

                for (let i = ages; i < ageMax[0] + 1; i++) {
                  xAxis.push(i);
                  if (i == ages || i == flag1 || i == flag2 || i == flag3) {
                    dataX.push(i);
                    dataArry3.push(val.personNeedPv)
                  }
                }
              }

            }
          } else if (val.one > 25) { //超过25% cap情景
            that.text = `若按照上述月收入分配方案持续进行养老资金储备，您在退休前可以大幅降低养老储备缺口，距离预期养老生活更近一步。
           `;
            dataArry3 = null;
            dataArry1 = linkArry1 ? linkArry1.slice(ages, retiredAge + 1) : [];
            dataArry2 = linkArry2 ? linkArry2.slice(ages, retiredAge + 1) : [];
            for (let i = ages; i < retiredAge + 1; i++) {
              xAxis.push(i);
            }
            //图形位置
            flag1 = ages + Math.round((retiredAge - ages) / 3);
            flag2 = ages + Math.round((retiredAge - ages) / 3 * 2);
            flag3 = retiredAge;
            for (let i = ages; i < retiredAge + 1; i++) {
              if (i == ages || i == flag1 || i == flag2 || i == flag3) {
                dataX.push(i);
                // dataArry3.push(val.personNeedPv)
              }
            }
          } else { //5%-45%  正常情景
            that.text = `若按照上述月收入分配方案持续进行养老资金储备，预计您在退休前可以完成养老储备目标，实现<span>${texts}</span>养老生活。`;
            dataArry1 = linkArry1 ? linkArry1.slice(ages, retiredAge + 1) : [];
            dataArry2 = linkArry2 ? linkArry2.slice(ages, retiredAge + 1) : [];
            xAxis = [];
            for (let i = ages; i < retiredAge + 1; i++) {
              xAxis.push(i);
            }
            //图形位置
            flag1 = ages + Math.round((retiredAge - ages) / 3);
            flag2 = ages + Math.round((retiredAge - ages) / 3 * 2);
            flag3 = retiredAge;

            for (let i = ages; i < retiredAge + 1; i++) {
              if (i == ages || i == flag1 || i == flag2 || i == flag3) {
                dataX.push(i);
                dataArry3.push(val.personNeedPv)
              }
            }
          }
        };
        if (flag1 != null && flag2 != null) {
          let dataObj = that.flags(flag1, flag2, flag3, dataArry1, xAxis, dataArry2)
          dataArry1 = dataObj.arry1;
          dataArry2 = dataObj.arry2;
        }
        // console.log('dataX', dataX)
        // console.log('dataArry1', dataArry1)
        // console.log('dataArry2', dataArry2)
        // console.log('dataArry3', dataArry3)
        let seriesArry = [],
          legendData = [];
        seriesArry.push({
          name: '商业养老保险累积预估值',
          type: 'line',
          smooth: true,
          symbolSize: 0,
          label: {
            show: true,
            position: 'top',
            formatter(param) {
              // console.log('param', param.name)
              let val = '';
              if (param.name == flag1 || param.name == flag2 || param.name == flag3) {
                val = `${param.value.toFixed(1)}万元`;
              }
              return val
            },
            fontSize: 12,
            fontWeight: 'bold',
            color: '#FA6946',
            offset: [-40, 25],
          },
          data: dataArry1,
          areaStyle: {
            opacity: 1,
            color: '#FF7D5D',
          },
          lineStyle: {
            width: 0
          },
          color: '#FF7D5D',
          z: 1
        })
        seriesArry.push({
          name: '其他养老储蓄累积预估值',
          type: 'line',
          smooth: true,
          symbol: 'none',
          data: dataArry2,
          areaStyle: {
            opacity: 1,
            color: '#FEDBD2',
          },
          lineStyle: {
            width: 0
          },
          color: '#FEDBD2',
          z: 2
        })
        legendData.push('商业养老保险累积预估值')
        legendData.push('其他养老储蓄累积预估值')
        if (dataArry3 != null) {
          seriesArry.push({
            name: '养老储备目标',
            type: 'line',
            smooth: true,
            symbol: 'none',
            data: dataArry3,
            color: '#FFBB00',
            lineStyle: {
              type: 'dashed'
            },
            z: 3
          })
          legendData.push('养老储备目标')
        }
        // let dataZoom = [];
        // if (dataX.length > 10) {
        //   dataZoom = [{
        //     type: 'slider',
        //     filterMode: 'none',
        //     startValu: 0,
        //     endValue: 10,
        //     bottom: '22%',
        //     zoomLock: true,
        //     zoomOnMouseWheel: false,
        //     height: 0,
        //     borderColor: "transparent",
        //     moveHandleSize: 10,
        //     moveHandleStyle: {
        //       color: "#D5D5D7",
        //     },
        //     emphasis: {
        //       moveHandleStyle: {
        //         color: "#D5D5D7",
        //       }
        //     },
        //     textStyle: {
        //       color: "transparent"
        //     },
        //     showDataShadow: false,
        //     showDetail: false
        //   }]
        // }
        let option = {
          grid: {
            top: 50,
            bottom: 80,
            left: 30,
            right: 30
          },
          legend: {
            selectedMode: false, //取消图例上的点击事件
            left: 'center',
            icon: 'roundRect',
            bottom: 0,
            data: legendData,
            textStyle: {
              color: "#31394D",
              fontSize: 12
            },
            itemHeight: 6,
            itemWidth: 10
          },
          xAxis: [{
            name: '年龄(岁)',
            nameGap: -40,
            nameTextStyle: {
              color: "#D5D5D7",
              fontSize: 12,
              verticalAlign: "top",
              // lineHeight: 50
              padding: [22, 0, 0, 0]
            },
            // type: 'category',
            boundaryGap: false,
            data: dataX,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D5D5D7"
              }
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: "#D5D5D7"
              }
            },
            axisLabel: {
              interval: 0, // 坐标轴刻度标签的显示间隔
              textStyle: {
                color: "#828286",
                fontSize: 12
              },
              zIndex: 2
            },
          }],
          yAxis: [{
            name: '单位(万元)',
            type: 'value',
            nameTextStyle: {
              color: "#D5D5D7"
            },
            // interval: 5,
            axisTick: {
              show: true,
              lineStyle: {
                color: "#D5D5D7"
              }
            },
            axisLabel: {
              textStyle: {
                color: "#828286",
                fontSize: 12
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D5D5D7"
              }
            },
            splitLine: {
              show: false
            }
          }],
          // dataZoom: dataZoom,
          series: seriesArry
        }
        that.myChart6.clear();
        that.myChart6.setOption(option);
      },
      //位置红旗
      flags(flag1, flag2, flag3, arrys1, dataX, arrys2) {
        // console.log('flag1', flag1)
        // console.log('flag2', flag2)
        // console.log('flag3', flag3)
        let arryobj = {
          arry1: [],
          arry2: [arrys2[0]]
        };
        arrys1.forEach(function(item, index) {
          // console.log('index',index)
          if (index == 0) {
            arryobj.arry1.push({
              value: item,
              symbol: 'image://' + require('@/assets/img/ren.png'),
              symbolSize: [23, 32],
              // symbolKeepAspect: true,
              symbolOffset: [15, -15],
            })
          } else if (index == dataX.indexOf(flag1) || index == dataX.indexOf(flag2) || index == dataX.indexOf(
              flag3)) {
            arryobj.arry1.push({
              value: item,
              symbol: 'image://' + require('@/assets/img/qi.png'),
              symbolSize: 23,
              symbolKeepAspect: true,
              symbolOffset: [1, -10],
            })
            arryobj.arry2.push(arrys2[index])
          }
        })
        return arryobj

      }
    },
    mounted() {
      this.personNeedPv = this.datas.personNeedPv;
      this.amountNum = this.datas.amountNum;
      this.myChart6 = this.$echarts.init(this.$refs.main6);
      if (this.personNeedPv > 0 && this.personNeedPv > this.amountNum) {
        this.getEcharts6_1(this.datas);
      } else {
        this.getEcharts6_2(this.datas);
      }
    },
  };
</script>
<style scoped>
  #echart6 {
    width: 100%;
    height: 250px;
    position: relative;
    z-index: 1;
  }

  .wenben {
    font-size: 1.4rem;
    font-weight: 400;
    color: #302E38;
    line-height: 2.9rem;
    margin-top: 1.7rem;
    box-sizing: border-box;
    padding-left: 1.5rem;
  }

  /deep/.wenben span {
    font-size: 2.6rem;
    font-weight: bold;
    color: #FA6946;
    box-sizing: border-box;
    padding: 0 0.5rem;
  }
</style>
