<template>
  <div class="pop">
    <div class="popBox">
      <div class="popBoxClose">
        <img src="@/assets/img/popClose.png" @click.stop="$emit('closePop')" />
      </div>
      <div class="popBoxs">
        <div class="popBoxss">
          <div class="popBoxCont" v-html="texts">
          </div>
          <img src="@/assets/img/popImg.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      'texts': {
        type: String,
        default: ''
      }
    },
    data() {
      return {

      }
    }
  }
</script>

<style>
</style>
