<template>
  <div class="slider">
    <!-- <div id="echartA" ref="mainA"></div> -->
    <div class="sliderBox" ref="sliderBox" @scroll="onScroll" @touchend="onEchartsB" @touchstart="offEchartsB">
      <div id="echartA" ref="mainA" :style="{'width':widths+'px'}"></div>
      <div :style="{'width':widths+'px'}" class="echartBBox">
        <div id="echartB" ref="mainB" :style="{'width':widths+'px'}"></div>
      </div>
      <div class="echartMountain" :style="{'width':widths+'px'}" v-if="echartMountain">
        <div class="echartMountainBox">
          <div v-for="(item,index) in echartMountain" :key="index" class="echartMountainTitle"
            :class="{'on':index==dataIndex&&classShow}"
            :style="{'bottom': ((findMax(item)) * 100) +'%','left':(item.indexOf(findMax(item))/10)+'%','margin-left':index==0?'-2rem':index==1?'-3rem':index==2?'-1.8rem':index==3?'-1.2rem':index==4?'-0.8rem':index==5?'1.8rem':index==6?'2.2rem':'0'}">
            家庭收支盈余</div>
        </div>
      </div>
      <div class="sliderLi" :style="{'width':widths+'px'}">
        <div v-for="(item,index) in lineArry" :key="index" class="sliderLiItem"
          :style="{'width':((item.max - item.min + 1) / 90).toFixed(2) * 100 +'%'}" :title="item.name">
          <div class="sliderLiItemIcon">
            <span>{{item.iconName}}</span>
            <i class="el-icon-caret-bottom
 sliderLiItemIcons"></i>
          </div>
          <span>{{item.name}}</span>
        </div>
        <!-- <van-slider v-model="value" bar-height="10px" @change="onChange" class="sliderItem" /> -->
      </div>
    </div>
    <div class="sliderBoxCent">
      <i class="el-icon-caret-top sliderBoxCentIcon"></i>
    </div>
    <!-- {{echartMountain}} -->
    <div class="echartsleng">
      <!-- data1 养老需求 data2  抚育需求 data3 健康风险 data4 身故风险 -->
      <div class="echartsLink" v-for="item in 4" :key="item">
        <i :style="{'border-color':color[item -1]}"></i>
        <span v-if="item==1">养老需求</span>
        <span v-else-if="item==2">抚育需求</span>
        <span v-else-if="item==3">健康风险</span>
        <span v-else-if="item==4">身故风险</span>
      </div>
    </div>
    <!-- <p>{{titleName}}</p>-->
    <!-- <p>进度：{{value}}</p> -->
    <div class="xingTitle">
      <img :src="titleImg" class="xingTitleHeadImg" />
      <p>{{titleName}}</p>
      <img src="@/assets/img/pop.png" class="xingTitleIcon" @click="tipShow=true;" />
    </div>
    <van-swipe class="my-swipe" :touchable="false" :show-indicators="false" ref="swipe">
      <van-swipe-item v-for="(item,index) in sliderTableArry" :key="index">
        <div class="sliderTable">
          <div class="sliderTableLeft">
            <div class="sliderTableLeftTitle">
              <p>养老储备</p>
              <p>教育规划</p>
              <p>资产管理</p>
              <p>疾病康复<br />健康管理</p>
              <p>意外伤害</p>
              <p>身故保障</p>
            </div>
            <div class="sliderTableLeftSmall">
              <p class="sliderTableLeftSmallFirst">年金险&nbsp;\&nbsp;两全险</p>
              <div>
                <p>重疾险</p>
                <p>医疗险</p>
              </div>
              <p>意外险</p>
              <p>寿险</p>
            </div>
          </div>
          <div class="sliderTableRight">
            <div class="sliderTableRightRow" v-for="(items,indexs) in item" :key="'xingRow'+indexs"
              :style="{'width':(100 / item.length)+'%'}">
              <div class="sliderTableRightRowTitle">{{items.title}}</div>
              <div class="sliderTableRightRowXing" v-for="(itemss,indexss) in items.xing" :key="'xing'+indexss">
                <div class="sliderTableRightRowXingBox" v-if="itemss==0">
                  <img src="@/assets/img/xing1.png" /><img src="@/assets/img/xing1.png" /><img
                    src="@/assets/img/xing1.png" />
                </div>
                <div class="sliderTableRightRowXingBox" v-else-if="itemss==0.5">
                  <img src="@/assets/img/xing2.png" /><img src="@/assets/img/xing1.png" /><img
                    src="@/assets/img/xing1.png" />
                </div>
                <div class="sliderTableRightRowXingBox" v-else-if="itemss==1">
                  <img src="@/assets/img/xing3.png" /><img src="@/assets/img/xing1.png" /><img
                    src="@/assets/img/xing1.png" />
                </div>
                <div class="sliderTableRightRowXingBox" v-else-if="itemss==1.5">
                  <img src="@/assets/img/xing3.png" /><img src="@/assets/img/xing2.png" /><img
                    src="@/assets/img/xing1.png" />
                </div>
                <div class="sliderTableRightRowXingBox" v-else-if="itemss==2">
                  <img src="@/assets/img/xing3.png" /><img src="@/assets/img/xing3.png" /><img
                    src="@/assets/img/xing1.png" />
                </div>
                <div class="sliderTableRightRowXingBox" v-else-if="itemss==2.5">
                  <img src="@/assets/img/xing3.png" /><img src="@/assets/img/xing3.png" /><img
                    src="@/assets/img/xing2.png" />
                </div>
                <div class="sliderTableRightRowXingBox" v-else-if="itemss==3">
                  <img src="@/assets/img/xing3.png" /><img src="@/assets/img/xing3.png" /><img
                    src="@/assets/img/xing3.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <!-- 弹窗 -->
    <van-overlay :show="tipShow" @click="tipShow = false" z-index="120">
      <pops :texts="popText" @closePop="tipShow = false"></pops>
    </van-overlay>
  </div>
</template>

<script>
  import {
    link,
    mountain
  } from "@/api/link.js"
  import pops from '@/components/pops.vue'
  export default {
    components: {
      pops
    },
    data() {
      return {
        echartLink: null,
        echartMountain: null,
        myChartA: null,
        myChartB: null,
        viewWidth: document.documentElement.clientWidth,
        widths: 90* 10,
        value: 0,
        dataIndex: 0,
        classShow: true,
        titleName: '咿呀学语期',
        titleImg: require('@/assets/img/tabImg1.png'),
        popTextArry: ['健康快乐成长是天下父母最大的心愿，婴幼儿的健康和意外风险相对较高，需要重疾险、医疗险、意外险提供保障；此外，可以提前进行教育金储备，满足未来的教育需求。',
          '此阶段学业繁重，课外补习班、兴趣班等教育支出较多，需要着重对教育资金进行储备和规划；同时，青少年的健康、意外风险也应重点关注。',
          '在毕业步入社会后，开始拥有一定的经济能力，应首要关注自己和父母的健康风险保障，同时也可以考虑提前为父母进行养老资金储备、规划和管理。',
          '组建家庭后，处在事业的上升期，同时也将承担买房买车等家庭建设责任，夫妻二人的健康和意外保障是重中之重；此外也应该着手对家庭资产进行科学管理，为可预期的家庭结构变化做好准备。',
          '此阶段“上有老、下有小”，作为家庭经济支柱，承担着赡养父母、抚育子女的重要责任，一旦遭遇风险，对家庭的创伤较为严重，因此自身的健康和意外风险保障应排在首位。此外，也应对子女的成长教育和父母的健康养老进行储备规划。',
          '子女已经成年就业，不再需要受到特殊照顾，自己和父母的健康及养老保障重新成为关注重点，同时也应提前做好财富传承规划。',
          '此阶段开始享受退休生活，需要关注自身和配偶的健康、养老以及家庭财富传承；如有余力，也可为子孙后代提供一定的健康及教育保障支持。'
        ],
        popText: '健康快乐成长是天下父母最大的心愿，婴幼儿的健康和意外风险相对较高，需要重疾险、医疗险、意外险提供保障；此外，可以提前进行教育金储备，满足未来的教育需求。',
        tipShow: false,
        lineArry: [{
          name: '咿呀学语期',
          iconName: '出生',
          icon: require('@/assets/img/tabImg1.png'),
          min: 0,
          max: 6,
        }, {
          name: '少年求学期',
          iconName: '求学',
          icon: require('@/assets/img/tabImg2.png'),
          min: 7,
          max: 18,

        }, {
          name: '单身贵族期',
          iconName: '就业',
          icon: require('@/assets/img/tabImg3.png'),
          min: 19,
          max: 25,
        }, {
          name: '二人世界期',
          iconName: '新婚',
          icon: require('@/assets/img/tabImg4.png'),
          min: 26,
          max: 32,
        }, {
          name: '家庭成长期',
          iconName: '生育',
          icon: require('@/assets/img/tabImg5.png'),
          min: 33,
          max: 45,
        }, {
          name: '子女离巢期',
          iconName: '离巢',
          icon: require('@/assets/img/tabImg6.png'),
          min: 46,
          max: 60,
        }, {
          name: '安度晚年期',
          iconName: '退休',
          icon: require('@/assets/img/tabImg7.png'),
          min: 61,
          max: 90,
        }],
        //山峰高度值
        // peakArry: [{
        //   name: '家庭收支盈余',
        //   data: [0, 0.1, 0]
        // }, {
        //   name: '家庭收支盈余',
        //   data: [0, 0.1, 0]
        // }, {
        //   name: '家庭收支盈余',
        //   data: [0, 0.5, 0]
        // }, {
        //   name: '家庭收支盈余',
        //   data: [0, 0.7, 0]
        // }, {
        //   name: '家庭收支盈余',
        //   data: [0, 0.9, 0]
        // }, {
        //   name: '家庭收支盈余',
        //   data: [0, 1, 0]
        // }, {
        //   name: '家庭收支盈余',
        //   data: [0, 0.8, 0]
        // }],
        // peakDate: {
        //   name: '家庭收支盈余',
        //   data: [0, 0.5, 0]
        // },
        //表格星星
        sliderTableArry: [
          [{
            title: '本人',
            xing: [0, 2.5, 0, 3, 3, 1.5]
          }],
          [{
            title: '本人',
            xing: [0, 3, 0, 3, 3, 1.5]
          }],
          [{
            title: '本人',
            xing: [0.5, 0, 1, 2.5, 3, 1]
          }, {
            title: '父母',
            xing: [1.5, 0, 0, 2.5, 3, 0.5]
          }],
          [{
            title: '本人/配偶',
            xing: [1, 0, 1.5, 3, 3, 1.5]
          }, {
            title: '父母',
            xing: [2, 0, 0, 3, 3, 2.5]
          }],
          [{
            title: '本人/配偶',
            xing: [2, 0, 2, 3, 3, 3]
          }, {
            title: '父母',
            xing: [3, 0, 0, 2.5, 3, 2]
          }, {
            title: '子女',
            xing: [0, 3, 0, 3, 3, 0.5]
          }],
          [{
            title: '本人/配偶',
            xing: [3, 0, 1.5, 3, 3, 2]
          }, {
            title: '父母',
            xing: [2, 0, 0, 2, 3, 1]
          }, {
            title: '子女',
            xing: [0, 2, 0, 2.5, 3, 2.5]
          }],
          [{
            title: '本人/配偶',
            xing: [3, 0, 1, 3, 3, 1]
          }, {
            title: '父母',
            xing: [1, 0, 0, 1, 3, 0]
          }, {
            title: '子女',
            xing: [0, 1.5, 0, 1, 3, 1.5]
          }]
        ],
        color: ['#FFBB00', '#56D398', '#69B8F6', '#D3155B'],
        option1: {
          grid: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
          color: ['#FFBB00', '#56D398', '#69B8F6', '#D3155B'],
          xAxis: {
            show: false,
            boundaryGap: false,
            data: [],
          },
          yAxis: {
            show: false,
            splitLine: {
              show: false
            },
            max: 1,
            min: 0,
            interval: 0.1,
          },
          series: []
        },
        option2: {
          grid: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
          color: '#FEDBD2',
          xAxis: {
            show: false,
            boundaryGap: false,
            data: [],
          },
          yAxis: {
            max: 1,
            min: 0,
            interval: 0.1,
            splitLine: {
              show: false
            }
          },
          series: []
        }
      }
    },
    mounted() {
       // console.log(this.$store.state.reportTabIndex)
      this.viewWidth = document.documentElement.clientWidth;
      this.myChartA = this.$echarts.init(this.$refs.mainA);
      this.myChartB = this.$echarts.init(this.$refs.mainB);
      this.dataIndex = this.$store.state.reportTabIndex;
      this.getEchartsA();
      this.getEchartsB();
      this.echartLink = link;
      this.echartMountain = mountain;
    },
    watch: {
      '$store.state.reportTabIndex': {
        handler(newVal, oldVal) {
           // console.log('tabs',newVal)
          this.dataIndex = newVal
          this.onSliderBox()
          // this.getEchartsB();
        },
        deep: true
      },
    },
    methods: {
      onSliderBox() {
        let arry = this.lineArry;
        let qu1 = this.widths * ((arry[0].max - arry[0].min + 1) / 90).toFixed(2),
          qu2 = this.widths * ((arry[1].max - arry[1].min + 1) / 90).toFixed(2),
          qu3 = this.widths * ((arry[2].max - arry[2].min + 1) / 90).toFixed(2),
          qu4 = this.widths * ((arry[3].max - arry[3].min + 1) / 90).toFixed(2),
          qu5 = this.widths * ((arry[4].max - arry[4].min + 1) / 90).toFixed(2),
          qu6 = this.widths * ((arry[5].max - arry[5].min + 1) / 90).toFixed(2);
        // qu7 = this.widths * ((arry[6].max - arry[6].min + 1) / 90).toFixed(2);
        if (this.dataIndex == 0) {
          this.$refs.sliderBox.scrollLeft = 0;
        } else if (this.dataIndex == 1) {
          this.$refs.sliderBox.scrollLeft = qu1 + 1
        } else if (this.dataIndex == 2) {
          this.$refs.sliderBox.scrollLeft = qu1 + qu2 + 1
        } else if (this.dataIndex == 3) {
          this.$refs.sliderBox.scrollLeft = qu1 + qu2 + qu3 + 1
        } else if (this.dataIndex == 4) {
          this.$refs.sliderBox.scrollLeft = qu1 + qu2 + qu3 + qu4 + 1
        } else if (this.dataIndex == 5) {
          this.$refs.sliderBox.scrollLeft = qu1 + qu2 + qu3 + qu4 + qu5 + 1
        } else if (this.dataIndex == 6) {
          this.$refs.sliderBox.scrollLeft = qu1 + qu2 + qu3 + qu4 + qu5 + qu6 + 1
        }
        this.titleName = arry[this.dataIndex].name;
        this.titleImg = arry[this.dataIndex].icon;
        //替换弹窗个文本
        this.popText = this.popTextArry[this.dataIndex];
        this.getEchartsA();
        this.getEchartsB();
        let that = this;
        this.$refs.mainB.classList.add('off');
        this.$refs.mainB.classList.remove('on');
        setTimeout(function() {
          that.$refs.mainB.classList.add('on');
          that.$refs.mainB.classList.remove('off');
        }, 500)
      },
      getEchartsA() {
        let that = this;
        let dataObj = [];
        //渲染x轴
        that.option1.xAxis.data = link.data1;
        //渲染数据
        for (let i = 0; i < 4; i++) {
          //data1 养老需求 data2  抚育需求 data3 健康风险 data4 身故风险
          dataObj.push({
            name: i == 0 ? '养老需求' : i == 1 ? '抚育需求' : i == 2 ? '健康风险' : '身故风险',
            data: link['data' + (i + 1)],
            type: 'line',
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 2,
              type: 'dashed'
            },
          })
        }
        that.option1.series = dataObj
        that.myChartA.clear();
        that.myChartA.setOption(that.option1);
        window.addEventListener("resize", function() {
          that.myChartA.resize();
        });
      },
      getEchartsB() {
        let that = this;
        // console.log(that.dataIndex)
        //渲染x轴
        that.option2.xAxis.data = mountain[0];
        // let active = [];
        // for (let i = 0; i < mountain[that.dataIndex].length; i++) {
        //   if (mountain[that.dataIndex][i] > 0) {
        //     active.push(mountain[that.dataIndex][i])
        //   }
        // }
        // let min = that.lineArry[that.$store.state.reportTabIndex].min,
        // max = that.lineArry[that.$store.state.reportTabIndex].max;
        // let active = mountain[that.$store.state.reportTabIndex].slice(min-1,max);
        // console.log(active)

        let dataObj = [];
        for (let i = 0; i < mountain.length; i++) {
          // let seriesData = [];
          // let mountainMax = that.findMax(mountain[i]); //取最大值
          // let mountainMaxIndex = mountain[i].indexOf(mountainMax); //最大值的索引
          // for (let r = 0; r < mountain[i].length; r++) {
          // let items = mountain[i][r]; //取每一个值
          // if (active.indexOf(items) > -1) {
          //   seriesData.push({
          //     value: items,
          // emphasis:{
          //   itemStyle:{
          //     color: "#FA6946"
          //   }
          // },
          // itemStyle: {
          //   borderWidth: 0,
          //   color: "#FA6946",
          // },
          // })
          // if (r < mountainMaxIndex) {
          //   seriesData.push({
          //     value: items,
          //     // itemStyle: {
          //     //   color: '#FA6946',
          //     // }
          //   })
          // } else if (r == mountainMaxIndex) {
          //   seriesData.push({
          //     value: items,
          //     // itemStyle: {
          //     //   color: '#FA6946',
          //     // }
          //   })
          // } else {
          //   seriesData.push({
          //     value: items,
          //     // itemStyle: {
          //     //   color: '#FA6946',
          //     // }
          //   })
          // }

          // } else {
          //   seriesData.push({
          //     value: items,
          // itemStyle: {
          //   borderWidth: 0,
          //   color: "rgba(245,180,163,0.5)",
          // },
          // emphasis:{
          //   disabled:false
          // }
          // itemStyle: {
          //   borderWidth: 0,
          //   color: "#FEDBD2",
          // },
          // })
          // if (r < mountainMaxIndex) {
          //   seriesData.push({
          //     value: items,
          //     // itemStyle: {
          //     //   color: "rgba(250,105,70,0.5)",
          //     // }
          //   })
          // } else if (r == mountainMaxIndex) {
          //   seriesData.push({
          //     value: items,
          //     // itemStyle: {
          //     //   color: "rgba(250,105,70,0.5)",
          //     // }
          //   })
          // } else {
          //   seriesData.push({
          //     value: items,
          //     // itemStyle: {
          //     //   color: "rgba(250,105,70,0.5)",
          //     // }
          //   })
          // }

          // seriesData.push(mountain[i][r])
          //   }
          // }

          dataObj.push({
            // data: seriesData,
            data: mountain[i],
            type: 'bar',
            barWidth: '100.1%',
            barGap: 0,
            barCategoryGap: 0,
            large: true, //是否开启大数据量优化
            largeThreshold: 50,
            progressive: 50,
            zlevel: that.dataIndex != i ? 1 : 2,
            itemStyle: {
              borderWidth: 0,
              color: that.dataIndex != i ? "rgba(245,180,163,0.5)" : "#FA6946"
            },
            // emphasis: {
            //   itemStyle: {
            //     color: "#FA6946"
            //   }
            // }
          })
        }
        // for (let i = 0; i < mountain.length; i++) {
        //   // 获取模块高度*坡度比例
        //   let top = -(this.$refs.mainB.offsetHeight * that.peakDate.data[1]);
        //   // 获取模块宽度 /2
        //   let left = -(this.$refs.mainB.offsetWidth / 2);
        //   //data1 养老需求 data2  抚育需求 data3 健康风险 data4 身故风险
        //   dataObj.push({
        //     name: '家庭收支盈余',
        //     data: mountain[i],
        //     type: 'line',
        //     smooth: true,
        //     showSymbol: false,
        //     zlevel:that.$store.state.reportTabIndex == i? 2:1,
        //     areaStyle: {
        //       color: that.$store.state.reportTabIndex == i? "rgba(250,105,70,0.8)":"rgba(254,219,210,0.8)"
        //     },
        //     lineStyle: {
        //       width: 0
        //     },
        //     // endLabel: {
        //     //   show: true,
        //     //   formatter: '{a}',
        //     //   color: '#000',
        //     //   fontSize: 14,
        //     //   align: "center",
        //     //   width: '100%',
        //     //   offset: [left, top]
        //     // }
        //   })
        // }
        that.$nextTick(function() {
          that.option2.series = dataObj
          that.myChartB.clear();
          that.myChartB.setOption(that.option2);
          window.addEventListener("resize", function() {
            that.myChartB.resize();
          });
        })

      },

      // 数组取最大值
      findMax(arr) {
        // let that = this;
        // that.$nextTick(function() {
        return Math.max(...arr);
        // })
        // let max = arr[0];
        // for (let i = 1; i < arr.length; i++) {
        //   if (arr[i] > max) {
        //     max = arr[i];
        //   }
        // }
        // return max;
      },
      // createRandom2(num, min, max) { //num表示生成几个结果，min随机数最小范围，max随机数最大范围
      //   var arr = [];
      //   var json = {};
      //   while (arr.length < num) {
      //     //产生单个随机数
      //     var ranNum = Math.ceil(Math.random() * (max - min)) + min;
      //     //通过判断json对象的索引值是否存在 来标记 是否重复
      //     if (!json[ranNum]) {
      //       json[ranNum] = 1;
      //       arr.push(ranNum);
      //     }
      //   }
      //   return arr;
      // },
      //滚动条切换
      onScroll() {
         let that = this;
        that.$nextTick(function() {
        // that.$refs.mainB.classList.remove('on');
        // console.log(that.$refs.sliderBox.scrollLeft)
        // this.value = event.target.scrollLeft;
        that.value = that.$refs.sliderBox.scrollLeft;
        let arry = that.lineArry;
        let qu1 = that.widths * ((arry[0].max - arry[0].min + 1) / 90).toFixed(2),
          qu2 = that.widths * ((arry[1].max - arry[1].min + 1) / 90).toFixed(2),
          qu3 = that.widths * ((arry[2].max - arry[2].min + 1) / 90).toFixed(2),
          qu4 = that.widths * ((arry[3].max - arry[3].min + 1) / 90).toFixed(2),
          qu5 = that.widths * ((arry[4].max - arry[4].min + 1) / 90).toFixed(2),
          qu6 = that.widths * ((arry[5].max - arry[5].min + 1) / 90).toFixed(2),
          qu7 = that.widths * ((arry[6].max - arry[6].min + 1) / 90).toFixed(2);
        if (qu1 > that.value) {
          //表格数据切换
          that.dataIndex = 0;
        } else if ((qu1 + qu2) > that.value) {
          that.dataIndex = 1;
        } else if ((qu1 + qu2 + qu3) > that.value) {
          that.dataIndex = 2;
        } else if ((qu1 + qu2 + qu3 + qu4) > that.value) {
          that.dataIndex = 3;
        } else if ((qu1 + qu2 + qu3 + qu4 + qu5) > that.value) {
          that.dataIndex = 4;
        } else if ((qu1 + qu2 + qu3 + qu4 + qu5 + qu6) > that.value) {
          that.dataIndex = 5;
        } else if ((qu1 + qu2 + qu3 + qu4 + qu5 + qu6 + qu7) > that.value) {
          that.dataIndex = 6;
        }
        // this.refs.echartMountainTitle[this.dataIndex].style.opacity=0;
        // document.getElementsByClassName('echartMountainTitle').classList.remove("on");
        that.titleName = arry[that.dataIndex].name;
        that.titleImg = arry[that.dataIndex].icon;

        that.$refs.swipe.swipeTo(that.dataIndex);
        //线数据替换
        that.getEchartsA();
        //山峰数据替换
        // this.peakDate = this.peakArry[6];
        that.getEchartsB();
        //替换弹窗个文本
        that.popText = that.popTextArry[that.dataIndex];
        // tab切换

        // that.$refs.mainB.classList.remove('on');

        // that.$refs.mainB.style.bottom = '-100%';
          that.$store.commit('setReportTabIndexs', that.dataIndex);

          // that.$refs.mainB.style.bottom = '0';
          // that.$refs.mainB.classList.add('on');
        })
        // setTimeout(function(){

        // },500)
        // that.$refs.mainB.classList.remove('off');
      },
      onEchartsB() {
        this.$refs.mainB.classList.add('on');
        this.classShow = true
      },
      offEchartsB() {
        this.classShow = false;
        this.$refs.mainB.classList.remove('on');
      }
    },
  }
</script>

<style scoped>
  @import url("../../../assets/style/tables.css");

  #echartA {
    height: 130px;
  }

  #echartB,
  .echartBBox {
    /* width: 100%; */
    height: 100%;
  }

  .echartBBox {
    position: relative;
    overflow: hidden;
  }

  #echartA {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 2;
    /* width: 100%; */
    /* height: 100px; */
  }

  #echartB {
    position: absolute;
    left: 0;
    bottom: -100%;
    z-index: 1;
    /* width: 100%; */
    /* height: 100px; */
    transition: bottom 0.2s;
  }

  #echartB.on {
    bottom: 0;
    transition: bottom 1s;
  }
</style>
