<template>
  <div>
    <div id="echart1" ref="main"></div>
  </div>
</template>
<script>
  export default {
    props: {
      datas: {
        type: Object,
      }
    },
    data() {
      return {
        myChartw: null,
        interval:null,
        option: {
          grid: {
            top: 30,
            bottom: 30,
            left: 100,
            right: 90
          },
          xAxis: {
            max: 0,
            splitLine: {
              show: false
            },
            offset: 10,
            axisLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisTick: {
              show: false
            },
          },
          yAxis: {
            data: ['TOP 10%', 'TOP 30%', 'TOP 50%'],
            inverse: true,
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#F84417',
                type: 'solid',
                width: 1,
              },
            },
            axisLabel: {
              margin: 1,

              formatter: function(value) {
                let texts = '';
                if (value == 'TOP 10%') {
                  texts = '{imgs1|} {value|' + value + '}';
                } else if (value == 'TOP 30%') {
                  texts = '{imgs2|} {value|' + value + '}';
                } else if (value == 'TOP 50%') {
                  texts = '{imgs3|} {value|' + value + '}';
                }
                return texts;
              },
              rich: {
                value: {
                  padding: [8, 8, 5, 12],
                  fontSize: 13,
                  color: '#fff',
                  backgroundColor: '#FA6946',
                  borderRadius: [11, 0, 0, 11],
                },
                imgs1: {
                  width: 20,
                  height: 23,
                  backgroundColor: {
                    image: require('@/assets/img/echartsIcon1.png')
                  }
                },
                imgs2: {
                  width: 20,
                  height: 23,
                  backgroundColor: {
                    image: require('@/assets/img/echartsIcon2.png')
                  }
                },
                imgs3: {
                  width: 20,
                  height: 23,
                  backgroundColor: {
                    image: require('@/assets/img/echartsIcon3.png')
                  }
                }
              }
            },
          },

          series: [{
              // current data
              type: 'pictorialBar',
              symbol: 'image://' + require('@/assets/img/echartsImg1.png'),
              symbolRepeat: 'fixed',
              symbolMargin: '15%',
              symbolClip: true,
              symbolSize: [20, 19],
              symbolBoundingData: '',
              data: '',
              markLine: {
                symbol: 'none',
                label: {
                  show: false,
                },
                lineStyle: {
                  color: '#F84417',
                  type: 'solid',
                  opacity: 1,
                  width: 1
                },
              },
            },
            {
              // full data
              type: 'pictorialBar',
              itemStyle: {
                opacity: 1
              },
              label: {
                show: true,
                formatter: function(params) {
                  return `已储备\n${(Number(params.value) / 10000).toFixed(0)}万元`;
                },
                position: 'right',
                offset: [10, 0],
                color: '#ACACB0',
                // color: '#828286 ',
                fontSize: 13,
                lineHeight: 18,
              },
              animationDuration: 0,
              symbolRepeat: 'fixed',
              symbolMargin: '15%',
              symbol: 'image://' + require('@/assets/img/echartsImg2.png'),
              symbolSize: [20, 19],
              symbolBoundingData: '',
              data: '',
              z: 1
            }
          ]
        }
      };
    },
    watch: {
      datas: {
        handler(newVal, oldVal) {
          this.getEcharts1(newVal);
        },
        deep: true
      },
      '$store.state.echartsOne': {
        handler(newVal, oldVal) {
          this.getEcharts1(this.datas);
        },
        deep: true
      },
    },
    methods: {
      getEcharts1(val) {
        let that = this;
        let dataObj = [];
        dataObj[0] ={name:'TOP 10%',value:val.one};
        dataObj[1] = {name:'TOP 30%',value:val.two};
        dataObj[2] ={name:'TOP 50%',value:val.three};
        that.arry([val.one,val.two,val.three])
        that.option.series[0].data = dataObj
        that.option.series[1].data = dataObj

        that.myChart.clear();
        that.myChart.setOption(that.option);
        window.addEventListener("resize", function() {
          that.myChart.resize();
        });

      },
      arry(o) {
        let that = this;
        let arrys1 = o.sort((a, b) => {
          return a - b
        });
        that.option.xAxis.max = arrys1[2]
        that.option.series[0].symbolBoundingData = arrys1[2]
        that.option.series[1].symbolBoundingData = arrys1[2]
      }
    },
    beforeDestroy() {
      window.clearInterval(this.interval)
    },
    mounted() {
      this.myChart = this.$echarts.init(this.$refs.main);
      this.getEcharts1(this.datas);

      // let that = this;
      // //每隔半分钟执行一次
      // that.interval = setInterval(function(){
      //   that.getEcharts1(that.datas);
      // },30000)
    },
  };
</script>
<style scoped>
  #echart1 {
    width: 100%;
    height: 250px;
  }
</style>
