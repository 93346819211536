import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home/index.vue'
import login from '../views/login/index.vue'
import form from '../views/form/index.vue'
import report from '../views/report/index.vue'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: login
  },
  {
    path: '/home',
    component: home
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  }, {
    path: '/form',
    component: form
  }, {
    path: '/report',
    component: report
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to, from) => {
  function handleFontSize() {
    // 设置网页字体为默认大小
    WeixinJSBridge.invoke('setFontSizeCallback', {
      'fontSize': 1
    });
    // 重写设置网页字体大小的事件
    WeixinJSBridge.on('menu:setfont', function() {
      WeixinJSBridge.invoke('setFontSizeCallback', {
        'fontSize': 1
      });
    });
  }
  if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
    handleFontSize();
  }
})
export default router
