import wx from 'weixin-js-sdk'
import axios from "axios"; // 引入 axios 函数
import AMapLoader from '@amap/amap-jsapi-loader'
import that from '@/main'
// import request from "@/api/request.js"
let config = {
  debug: false, // 查看初始化结果，成功与否
  appId: "", //公众号的APPID
  nonceStr: "", // 生成签名的随机字符串
  timestamp: 0, // 生成签名的时间戳
  signature: "", // :微信生成的签名
  jsApiList: ["getLocation", 'updateTimelineShareData',
    'updateAppMessageShareData'
  ]
}
export default {
  //授权
  init() {
    let signLink =  /(Android)/i.test(navigator.userAgent) ? window.location.href.split('#')[0] : localStorage.getItem('hrefs');
    axios({
      //gh_3aa1eb26632c   gh_b47c5b804348
      method: "get",
      url: 'https://weixin.e-chinalife.com/mic/commBusi/jSignatureGet.action?p10=' +
        encodeURIComponent(
          "{\"p1\":\"1\",\"p2\":\"\",\"p6\":\"gh_b47c5b804348\",\"p13\":\""+signLink+"\"}"),
    }).then(res => {
      let configData = JSON.parse(decodeURIComponent(res.data)).data;
      if (configData) {
        config.appId = configData.appId
        config.nonceStr = configData.nonceStr
        config.timestamp = configData.timestamp
        config.signature = configData.signature
      }
      wx.config(config)
      wx.checkJsApi({
        jsApiList: ["getLocation", 'updateTimelineShareData',
          'updateAppMessageShareData'
        ],
        success: function(res) {
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
          // alert(res)
          // console.log(res, 'checkJsApi')
        }
      });
    })
  },
  //获取用户位置
  getLocations(callBack) {
    wx.ready(function() { //需在用户可能点击分享按钮前就先调用
      wx.getLocation({
        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function(res) {
          var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          //地址反向编码解析地址
          AMapLoader.load({
              key:localStorage.getItem("mapKey"), // 申请好的Web端开发者Key，首次调用 load 时必填
              version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
              plugins: ['AMap.Geocoder'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap) => {
              var geocoder = new AMap.Geocoder() //逆地理解析
              geocoder.getAddress([longitude, latitude], function(status, result) {
                if (result.info === 'DAILY_QUERY_OVER_LIMIT') {
                  that.$toast('自动定位已达上限，请手动选择！');
                  return false
                }
                if (result.info === 'ACCESS_TOO_FREQUENT') {
                  that.$toast('访问过于频繁，请稍后再试！');
                  return false
                }
                if (result.info === 'SERVICE_EXPIRED') {
                  that.$toast('服务到期，请续费');
                  return false
                }
                if (status === 'complete' && result.info === 'OK') {
                  that.$toast('定位成功');
                  //resolve({ type: '地理位置详细信息获取成功', ...result })
                  // console.log('地理位置详细信息获取成功')
                  let obj = result.regeocode.addressComponent;
                  let address = obj.province.substring(0, obj.province.length - 1) + '-' + obj.city
                    .substring(0, obj.city.length - 1);
                  callBack(address)
                  // 省obj。province - 市obj.city - 区obj.district - 乡镇/街道obj.township
                  //result.regeocode.formattedAddress全地址
                }
              })
            })
            .catch((error) => {
              console.log(error)
            });
        }
      });
    })
  },

  //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
  setUpdateTimelineShareData() {
    wx.ready(function() { //需在用户可能点击分享按钮前就先调用
      wx.updateTimelineShareData({
        title: '一分钟定制您的专属养老规划方案', // 分享标题
        link: window.location.href.split('#')[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: window.location.href.split('#')[0]+'/share.jpg', // 分享图标
        success: function() {
          // callBack()
        }
      })
    })
  },
  //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
  setUpdateAppMessageShareData() {
    wx.ready(function() { //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({
        title: '一分钟定制您的专属养老规划方案', // 分享标题
        desc: 'AI精算师为您精准测算未来养老需求、科学设计养老储备方案', // 分享描述
        link: window.location.href.split('#')[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: window.location.href.split('#')[0]+'/share.jpg', // 分享图标
        success: function() {
          // callBack()
        }
      })
    })
  }

}
