import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    play: true,
    adresshx: true,
    reportTabIndex: 0,
    reportTabIndexs: 0,
    echartsOne: 0,
    echartsTwo: 0,
    echartsThree: 0,
    echartsFour: 0,
    echartsFive: 0,
    echartsSex:0,
    fadeOut: 0,
    fadeOut1: 0,
  },
  mutations: {
    setPlay(state, value) {
      state.play = value;
    },
    setAdresshx(state, value) {
      state.adresshx = value;
    },
    setReportTabIndex(state, value) {
      state.reportTabIndex = value;
    },
    setReportTabIndexs(state, value) {
      state.reportTabIndexs = value;
    },
    setEchartsTwo(state) {
      state.echartsTwo++;
    },
    setEchartsSex(state) {
      state.echartsSex++;
    },
    setEchartsFour(state) {
      state.echartsFour++;
    },
    setEchartsFive(state) {
      state.echartsFive++;
    },
    setEchartsThree(state) {
      state.echartsThree++;
    },
    setEchartsOne(state) {
      state.echartsOne++;
    },
    setFadeOut(state) {
      state.fadeOut++;
    },
    setFadeOut1(state) {
      state.fadeOut1++;
    },
  },
  actions: {
    setReportTabIndex(state, value) {
      setTimeout(() => {
        state.commit('setReportTabIndex', value)
      }, 1000)
      // state.reportTabIndex = value;
    },
    setReportTabIndexs(state, value) {
      setTimeout(() => {
        state.commit('setReportTabIndexs', value)
      }, 1000)
      // state.reportTabIndexs = value;
    },
  },
  modules: {

  }
})
