<template>
  <div>
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'HelloWorld',
    props: {
      msg: String
    },
    mounted() {
      this.fonts()
      if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
        localStorage.setItem('hrefs', location.href.split('#')[0])
      }
    },
    methods: {
      fonts() {

          if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
            handleFontSize();
          } else {
            if (document.addEventListener) {
              document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
            } else if (document.attachEvent) {
              document.attachEvent("WeixinJSBridgeReady", handleFontSize);
              document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
            }
          }

          function handleFontSize() {
             // document.getElementsByTagName("html")[0].style.fontSize = '62.5%';
            // 设置网页字体为默认大小
            WeixinJSBridge.invoke('setFontSizeCallback', {
              'fontSize': 1
            });
            // 重写设置网页字体大小的事件
            WeixinJSBridge.on('menu:setfont', function(e) {
              // document.getElementsByTagName("html")[0].style.fontSize = '62.5%';
              // alert(JSON.stringify(e))
              WeixinJSBridge.invoke('setFontSizeCallback', {
                'fontSize': 1
              });
            });
          }
      },
    }
  }
</script>

<style scoped>
  /deep/ .van-overlay{
    z-index: 10
  }
</style>
