import request from "@/api/request.js"

export default {
  // 切换结果
  getlogin(data) {
    return request.get('/gs/userInfo/getInputInfoByOpenId?openId='+data.openid)
  },
  // 评测次数
  getSubmitCount() {
  	return request.get('/gs/userInfo/getSubmitCount')
  },
  // 行业代码
  getHangyeCode() {
  	return request.get('/system/dict/data/type/occ_class_code')
  },
  // 获取地址
  getAdress() {
  	return request.get('/gs/address/selectGsAddressResult')
  },
  // 开始评测
  getInfo(data) {
  	return request.post('/gs/userInfo/insertInputInfo',data)
  },
  // 获取地址code
  getAdressCode(data) {
  	return request.get('/gs/address/selectCodeByProOrCity?prov='+data.prov+'&city='+data.city)
  },
  // 获取高德地图Key
  getGaoDeKey() {
  	return request.get('/gs/userInfo/getGaoDeMapKey')
  },
  // 计数，跳转小程序
  getToPath(data) {
  	return request.get('/gs/userInfo/submitMiniProgramCount?id='+data.id)
  },
}

