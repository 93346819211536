import { render, staticRenderFns } from "./pops.vue?vue&type=template&id=bf15e020"
import script from "./pops.vue?vue&type=script&lang=js"
export * from "./pops.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports