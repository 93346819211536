<template>
  <div>
    <!-- <van-area title="地区" :area-list="areaList" @cancel="onClose" @confirm="onFinish" /> -->
    <van-cascader v-model="cascaderValue" title="请选择所在地区" :options="options" @close="onClose" @finish="onFinish"
      active-color="#302E38" :field-names="fieldNames" />
  </div>
</template>

<script>
  import api from '@/api/index.js'
  export default {
    props: ['datas'],
    data() {
      return {
        fieldNames: {
          text: 'label',
          value: 'code',
        },
        options: [],
        cascaderValue: '',
      }
    },
    watch: {
      datas: {
        // 数据变化时执行的逻辑代码
        handler(newName, oldName) {
          this.detials()
          let that = this;
          that.$nextTick(function() {
            that.cascaderValue = Number(newName)
          })

        },
        // 开启深度监听
        deep: true
      },
    },
    mounted() {
      this.detials()
      let that = this;
      that.$nextTick(function() {
        that.cascaderValue = Number(that.datas)
      })
    },
    methods: {
      async detials() {
        let res = await api.getAdress();
        if (res.code == 200) {
          this.options = res.data
        } else {
          this.$toast(res.msg);
          return false
        }
      },
      onFinish({
        selectedOptions
      }) {
        let fieldValue = selectedOptions.map((option) => (option.label + ',' + option.code)).join('-');
        this.$emit('confirm', fieldValue)
      },
      onClose() {
        this.$emit('confirm', '')
      },
    }
  }
</script>
<style scoped>
  /deep/.van-cascader__title {
    font-size: 2rem;
    font-weight: bold;
    color: #302E38;
    box-sizing: border-box;
    padding: 3rem 0 0;
  }

  /deep/.van-cascader__header {
    box-sizing: border-box;
    padding-left: 2.3rem;
    margin-bottom: 2rem;
  }

  /deep/.van-tab {
    font-size: 1.6rem;
  }

  /deep/.van-tabs__line {
    width: 1.7rem;
    height: .2rem;
    /* left: calc(50% - 0.85rem); */
    background-color: #302E38;
  }

  /deep/.van-cascader__selected-icon {
    display: none;
  }

  /deep/.van-cascader__option span {
    font-size: 1.6rem;
    font-weight: 400;
    color: #302E38;
  }

  /deep/.van-cascader__option--selected {
    background: linear-gradient(271deg, #FFFFFF 0%, #FFF5F2 67%, #FEDBD2 100%);
    border-radius: 0;
    height: 4rem;
    position: relative;
  }

  /deep/.van-cascader__option--selected::after {
    display: block;
    content: '';
    width: .4rem;
    height: 2.6rem;
    background: #F84417;
    border-radius: .3rem;
    position: absolute;
    left: 0;
    top: calc(50% - 1.3rem);
    z-index: 1;
  }

  /deep/.van-cascader__option--selected span {
    color: #F8461A;
    font-weight: bold;
  }
</style>
