<template>
  <div class="formBox headerBg1 headerBg2" ref="ad">
    <div class="headers headers1">
      <img src="@/assets/img/left.png" @click="$router.go(-1)">
      <span>精算养老规划助手</span>
      <img src="@/assets/img/headBg3.png" class="nius">
    </div>

    <div class="formCont formCont1">
      <div class="title">
        <p>性别</p>
      </div>
      <div class="forms form_item2">
        <div v-for="(item,index) in sexOptions" :key="index" class="sexItem" :class="{'sexOn':form.sex==item.id}"
          @click="onSex(item)">
          <img src="@/assets/img/man.png" v-if="item.text=='男性'&&form.sex!='M'" />
          <img src="@/assets/img/man1.png" v-if="item.text=='男性'&&form.sex=='M'" />
          <img src="@/assets/img/woman.png" v-if="item.text=='女性'&&form.sex!='F'" />
          <img src="@/assets/img/woman1.png" v-if="item.text=='女性'&&form.sex=='F'" />
          <span>{{item.text}}</span>
        </div>
      </div>

      <div class="title">
        <p>年龄</p>
      </div>
      <div class="forms form_item2" @click="agePop=true">
        <div class="ageText"><span>{{form.inputAge==""?'请选择您的年龄':form.inputAge+'岁'}}</span><img
            src="@/assets/img/down1.png">
        </div>
      </div>

      <div class="title">
        <p>地区</p>
      </div>
      <div class="forms form_item2">
        <div class="adressText" @click="addressPop=true">
          <span>{{form.addressName==""?'请选择地区':form.addressName}}</span>
          <img src="@/assets/img/down1.png" />
        </div>
        <div @click="getUserAddress" class="onAdress"><img src="@/assets/img/address.png" /><span>当前定位</span></div>
      </div>

      <div class="title">
        <p>月收入</p><img src="@/assets/img/pop.png" @click="popText = yueText;tipShow=true" />
      </div>
      <div class="forms form_item4">
        <div v-for="(item,index) in incomeOptions" :key="index" class="flex8"
          :class="{'incomeOn':form.monthIncome==item.text}" @click="form.monthIncome=item.text">
          {{item.text}}
        </div>
      </div>

      <div class="title">
        <p>退休后养老资金来源（多选）</p><img src="@/assets/img/pop.png" @click="socialTipShow=true" />
      </div>
      <div class="forms form_item2">
        <div class="sociaItem" :class="{'socialOn':isPerson=='Y'}"
          @click="isPerson=isPerson=='N'?'Y':'N'">
          个人
        </div>
        <div class="sociaItem" :class="{'socialOn':form.isSocialsecurity=='Y'}"
          @click="form.isSocialsecurity = form.isSocialsecurity=='N'?'Y':'N'">
          社保
        </div>
        <div class="sociaItem" :class="{'socialOn':form.pensionMethod=='childrenAssisted'}"
          @click="form.pensionMethod = form.pensionMethod=='childrenAssisted'?'selfFunded':'childrenAssisted'">
          子女
        </div>
      </div>
    </div>

    <div class="fold foldBox" :class="{'foldBoxs':moreShow==true}" @click="moreShow=!moreShow">
      <p><img src="@/assets/img/more.png" />
        <span>点击补充其他信息，结果更准确</span>
      </p>
      <img src="@/assets/img/down.png" class="foldBoxImg" :class="{'foldBoxImgs':moreShow==true}" />
    </div>

    <div class="formCont" :class="{'formConts':moreShow==true}" v-if="moreShow" style="padding-top: 0;">
      <div class="title">
        <p>行业</p>
      </div>
      <div class="forms form_item1" @click="industryPop=true">
        <div class="hangyeText">
          <span>{{form.occClassName==""?'请选择您的行业':form.occClassName}}</span>
          <img src="@/assets/img/down1.png">
        </div>
      </div>

      <div class="title">
        <p>投资偏好</p><img src="@/assets/img/pop.png" @click="popText = investText;tipShow=true" />
      </div>
      <div class="forms form_item2" style="border:0;padding-bottom: 0;margin-bottom: 1.3rem;">
        <div v-for="(item,index) in investOptions" :key="index" class="investItem"
          :class="{'investOn':form.investmentStyle==item.id}" @click="form.investmentStyle=item.id">
          {{item.text}}
        </div>
      </div>
      <p class="tip" v-if="form.investmentStyle!=''" style="border-bottom-style: dotted;"><img src="@/assets/img/tip.png" />
        <span v-if="form.investmentStyle=='robust'">稳健型：期望保本，大部分投资于存款、国债、货币基金等</span>
        <span v-else-if="form.investmentStyle=='balanced'">平衡型：为了更好的收益可以接受一定的本金损失，平衡投资于存款国债、基金股票等产品</span>
        <span v-else-if="form.investmentStyle=='aggressive'">进取型：具备丰富的投资经验，大部分投资于基金股票等产品</span>
      </p>

      <!-- <div class="title">
        <p>养老观念</p>
      </div>
      <div class="forms form_item2">
        <div v-for="(item,index) in conceptOptions" :key="index" class="conceptItem"
          :class="{'investOn':form.pensionMethod==item.id}" @click="form.pensionMethod=item.id">
          {{item.text}}
        </div>
      </div> -->

      <div class="title">
        <p>已有养老储备</p><img src="@/assets/img/pop.png" @click="popText = yiyouText;tipShow=true" />
      </div>
      <div class="forms form_item4">
        <div v-for="(item,index) in yiyouOptions" :key="index" class="flex8"
          :class="{'incomeOn':form.amountExistingStr==item.text}" @click="form.amountExistingStr=item.text">
          {{item.text}}
        </div>
      </div>

      <div class="title">
        <p>预期养老地区</p><img src="@/assets/img/pop.png" @click="popText = addressText;tipShow=true" />
      </div>
      <div class="forms form_item1">
        <div @click="addressPop1=true" class="oldAddress">
          <span>{{form.expectRegion==""?'请选择地区':form.expectRegion}}</span>
          <img src="@/assets/img/down1.png">
        </div>
      </div>
      <div class="title">
        <p>预期退休年龄</p>
      </div>
      <div class="forms form_item3">
        <div v-for="(item,index) in oldAgeOptions" :key="index" class="oldAgeItem"
          :class="{'investOn':form.retiredAge==item.id}" @click="onAge(maxAge>=Number(item.id)?true:false,item)">
          <span>{{item.text}}</span>
          <img src="@/assets/img/disable.png" class="investOff" v-if="maxAge>=Number(item.id)?true:false" />
        </div>
      </div>

      <div class="title">
        <p>预期养老生活</p>
      </div>
      <div class="forms form_item2" style="border:0;padding-bottom: 0;margin-bottom: 1.3rem;">
        <div v-for="(item,index) in lifeOptions" :key="index" class="investItem"
          :class="{'investOn':form.pensionStyle==item.id}" @click="form.pensionStyle=item.id">
          {{item.text}}
        </div>

      </div>
      <p class="tip" style="border:0;padding-bottom: 0;margin-bottom: 0;" v-if="form.pensionStyle!=''">
        <img src="@/assets/img/tip.png" />
        <span v-if="form.pensionStyle=='basic'">经济型：与退休前收入水平存在一定差异，满足衣食住行、医疗保健等基本养老需求</span>
        <span v-else-if="form.pensionStyle=='comfort'">舒适型：舒适养老，满足日常娱乐爱好支出，和工作期间相比生活质量略有下降</span>
        <span v-else-if="form.pensionStyle=='luxury'">轻奢型：养老生活水平与工作期间保持一致，支持高端品质养老生活</span>
      </p>

      <div class="pyramid">
        <div class="pyramidBox pyramidTop" :class="{'active':form.pensionStyle=='luxury'}">
          <img src="@/assets/img/jzt_tops.png" class="pyramidBoxImgBg" v-if="form.pensionStyle=='luxury'"/>
          <img src="@/assets/img/jzt_top.png" class="pyramidBoxImgBg" v-else/>
          <div class="pyramidTitle">
            <p>第三<br />层次</p>
            <p>轻奢养老</p>
          </div>
          <div class="pyramidText">
           <p>品质生活</p>
            <p>升级医疗</p>
          </div>
        </div>
        <div class="pyramidBox pyramidCenter"
          :class="{'active':form.pensionStyle=='comfort'||form.pensionStyle=='luxury'}">
          <img src="@/assets/img/jzt_centers.png" class="pyramidBoxImgBg" v-if="form.pensionStyle=='comfort'||form.pensionStyle=='luxury'"/>
          <img src="@/assets/img/jzt_center.png" class="pyramidBoxImgBg" v-else/>
          <div class="pyramidTitle">
            <p>第二<br />层次</p>
            <p>舒适养老</p>
          </div>
          <div class="pyramidText" style="width:51%;justify-content: space-between;">
            <p>娱乐爱好</p>
            <p>健康护理</p>
          </div>
        </div>
        <div class="pyramidBox pyramidBottom"
          :class="{'active':form.pensionStyle=='basic'||form.pensionStyle=='comfort'||form.pensionStyle=='luxury'}">
          <img src="@/assets/img/jzt_bottoms.png" class="pyramidBoxImgBg" v-if="form.pensionStyle=='basic'||form.pensionStyle=='comfort'||form.pensionStyle=='luxury'"/>
          <img src="@/assets/img/jzt_bottom.png" class="pyramidBoxImgBg" v-else/>
          <div class="pyramidTitle">
            <p>第一<br />层次</p>
            <p>经济养老</p>
          </div>
          <div class="pyramidText" style="width:62%;justify-content: space-between;">
            <p>衣食住行</p>
            <p>基础医疗</p>
          </div>
        </div>
      </div>
    </div>
    <a class="submit" @click="toStart">开始测算</a>
    <!-- 年龄选择器 -->
    <van-popup v-model="agePop" round position="bottom">
      <age @confirm="ageOnConfirm"></age>
    </van-popup>
    <!-- 地区选择 -->
    <van-popup v-model="addressPop" round position="bottom">
      <van-cascader v-model="cascaderValue" title="请选择所在地区" :options="options" @close="onClose" @finish="onFinish"
        active-color="#302E38" :field-names="fieldNames" />
      <!-- <adress @confirm="addressOnConfirm" ref="adressChild"></adress> -->
    </van-popup>
    <!-- 地区选择 -->
    <van-popup v-model="addressPop1" round position="bottom">
      <adresso @confirm="addressOnConfirm1" :datas="form.cityCode"></adresso>
    </van-popup>
    <!-- 行业选择器 -->
    <van-popup v-model="industryPop" round position="bottom">
      <industry @confirm="industryOnConfirm"></industry>
    </van-popup>
    <!-- 弹窗 -->
    <van-overlay :show="tipShow" @click="tipShow = false">
      <pops :texts="popText" @closePop="tipShow = false"></pops>
    </van-overlay>
    <!-- 社保情况弹窗 -->
    <van-overlay :show="socialTipShow" @click="socialTipShow = false">
      <popss @closePop="socialTipShow = false"></popss>
    </van-overlay>
  </div>

</template>

<script>
  import industry from './components/industry.vue'
  import age from './components/age.vue'
  // import adress from './components/address.vue'
  import adresso from './components/address1.vue'
  import pops from '@/components/pops.vue'
  import popss from './components/pops.vue'

  import api from '@/api/index.js'
  export default {
    components: {
      industry,
      age,
      // adress,
      adresso,
      pops,
      popss
    },
    data() {
      return {
        tipShow: false,
        popText: '',
        yueText: '月收入指您的每月税后收入，算法为：每月税后收入=每月税前收入-社保个人扣除-公积金个人扣除-企/职业年金个人扣除-个人所得税。',
        investText: '按照风险分散原理，将自己的养老储备科学地分配至保险、储蓄和投资，可以更好地平衡风险和收益。',
        addressText: '不同地区的养老支出水平、通胀率不同，养老压力也存在差异。',
        yiyouText: '已有养老储备指您当前已有的可供退休后消费使用的资金。',
        socialTipShow: false,
        sexOptions: [{
          id: 'M',
          text: '男性'
        }, {
          id: 'F',
          text: '女性'
        }],
        agePop: false,
        addressPop: false,
        addressPop1: false,
        incomeOptions: [{
          id: 1,
          text: '3千以下'
        }, {
          id: 2,
          text: '3千-6千'
        }, {
          id: 3,
          text: '6千-1万'
        }, {
          id: 4,
          text: '1万-1.5万'
        }, {
          id: 5,
          text: '1.5万-2万'
        }, {
          id: 6,
          text: '2万-3万'
        }, {
          id: 7,
          text: '3万-5万'
        }, {
          id: 8,
          text: '5万以上'
        }],
        yiyouOptions: [{
          id: 1,
          text: '0万'
        }, {
          id: 2,
          text: '0万-5万'
        }, {
          id: 3,
          text: '5-10万'
        }, {
          id: 4,
          text: '10-20万'
        }, {
          id: 5,
          text: '20-30万'
        }, {
          id: 6,
          text: '30-50万'
        }, {
          id: 7,
          text: '50-100万'
        }, {
          id: 8,
          text: '100万以上'
        }],

        // socialOptions: [{
        //   id: 'Y',
        //   text: '有'
        // }, {
        //   id: 'N',
        //   text: '无'
        // }],
        moreShow: false,
        industryPop: false,
        investOptions: [{
          id: 'robust',
          text: '稳健型'
        }, {
          id: 'balanced',
          text: '平衡型'
        }, {
          id: 'aggressive',
          text: '进取型'
        }],

        // conceptOptions: [{
        //   id: 'selfFunded',
        //   text: '自立养老'
        // }, {
        //   id: 'childrenAssisted',
        //   text: '养孩防老'
        // }],
        oldAgeOptions: [{
          id: 50,
          text: '50岁'
        }, {
          id: 55,
          text: '55岁'
        }, {
          id: 60,
          text: '60岁'
        }, {
          id: 65,
          text: '65岁'
        }, {
          id: 70,
          text: '70岁'
        }],
        lifeOptions: [{
          id: 'basic',
          text: '经济型'
        }, {
          id: 'comfort',
          text: '舒适型'
        }, {
          id: 'luxury',
          text: '轻奢型'
        }],
        maxAge: 0,
        isPerson: 'N',
        form: {
          id: '',
          createTime: '',
          openId: sessionStorage.getItem("openid"),
          // openId: '5464654324658',
          sex: '', //性别
          inputAge: '', //年龄
          addressName: '', //地区
          cityCode: '', //地区-市
          provCode: '', //地区-省
          monthIncome: '', //月收入
          isSocialsecurity: 'N', //社保情况
          occClassCode: '', //行业
          occClassName: '', //行业
          investmentStyle: 'balanced', //投资偏好
          pensionMethod: 'selfFunded', //养老观念
          amountExistingStr: '20-30万', //已有养老储备
          expectRegion: '', //预期养老地区
          expectCityCode: '', //预期养老地区-市
          expectProvCode: '', //预期养老地区-省
          retiredAge: '55', //预期养老年龄
          pensionStyle: 'basic', //预期养老生活
          izPerson: 'N'
        },
        fieldNames: {
          text: 'label',
          value: 'code',
        },
        options: [],
        // fieldValue: '',
        cascaderValue: '',
      }
    },
    mounted() {
      //获取上次填写数据
      if (localStorage.getItem("formData") != null) {
        this.form = JSON.parse(localStorage.getItem("formData"))
      }
      this.detials()
      // 高德地图key
      this.getGaoDeKeys()
    },
    methods: {
      // 高德地图key
      async getGaoDeKeys() {
        let res = await api.getGaoDeKey();
        if (res.code == 200) {
          localStorage.setItem("mapKey", res.data.mapKey)
        } else {
          this.$toast(res.msg);
          return false
        }
      },
      //性别选择
      onSex(item) {
        this.form.sex = item.id;
        if (item.id == 'F') {
          this.form.retiredAge = '55';
        } else {
          this.form.retiredAge = '60';
        }
        //调整预期退休年龄
        this.onYuqi()
      },
      //年龄选择
      ageOnConfirm(value) {
        this.form.inputAge = value;
        this.agePop = false;
        //已有养老储备
        if (value < 50) {
          this.form.amountExistingStr = this.yiyouOptions[0].text;
        } else if (value < 55) {
          this.form.amountExistingStr = this.yiyouOptions[3].text;
        } else if (value < 65) {
          this.form.amountExistingStr = this.yiyouOptions[4].text;
        }
        //  else if (value < 66) {
        //   this.form.amountExistingStr = this.yiyouOptions[5].text;
        // }
        //调整预期退休年龄
        this.onYuqi()
      },
      onYuqi() {
        let value = this.form.inputAge;
        //预期退休年龄
        if (this.form.sex == 'F') { //女
          if (value < 50) {
            this.maxAge = value + 5;
          } else if (value == 50) {
            this.maxAge = 54;
            this.form.retiredAge = 55
          } else {
            this.maxAge = value + 5;
            if (this.maxAge < 60) {
              this.form.retiredAge = 60
            } else if (this.maxAge < 65) {
              this.form.retiredAge = 65
            } else if (this.maxAge < 70) {
              this.form.retiredAge = 70
            }
          }
        } else { //男
          if (value < 55) {
            this.maxAge = value + 5;
            this.form.retiredAge = 60
          } else if (value == 55) {
            this.maxAge = 59;
            this.form.retiredAge = 60
          } else {
            this.maxAge = value + 5;
            if (this.maxAge < 60) {
              this.form.retiredAge = 60
            } else if (this.maxAge < 65) {
              this.form.retiredAge = 65
            } else if (this.maxAge < 70) {
              this.form.retiredAge = 70
            }
          }
        }
      },
      async detials() {
        let res = await api.getAdress();
        if (res.code == 200) {
          this.options = res.data
        } else {
          this.$toast(res.msg);
          return false
        }
      },
      onFinish({
        selectedOptions
      }) {
        let fieldValue = selectedOptions.map((option) => (option.label + ',' + option.code)).join('-');
        this.addressOnConfirm(fieldValue)
      },
      onClose() {
        this.addressOnConfirm('')
      },
      //地区
      addressOnConfirm(value) {
        if (value) {
          let sheng = value.split('-')[0],
            shi = value.split('-')[1];
          //地区
          this.form.addressName = sheng.split(',')[0] + '-' + shi.split(',')[0];
          this.form.provCode = Number(sheng.split(',')[1]); //省
          this.form.cityCode = Number(shi.split(',')[1]); //市
          //预期养老地区
          this.form.expectRegion = sheng.split(',')[0] + '-' + shi.split(',')[0];
          this.form.expectProvCode = Number(sheng.split(',')[1]); //省
          this.form.expectCityCode = Number(shi.split(',')[1]); //市
        }
        this.addressPop = false;
      },
      addressOnConfirm1(value) {
        if (value) {
          let sheng = value.split('-')[0],
            shi = value.split('-')[1];
          this.form.expectRegion = sheng.split(',')[0] + '-' + shi.split(',')[0];
          this.form.expectProvCode = Number(sheng.split(',')[1]); //省
          this.form.expectCityCode = Number(shi.split(',')[1]); //市
        }
        this.addressPop1 = false;
      },
      async getAdressCodes(a, b) {
        let obj = {
          prov: a,
          city: b
        }
        let res = await api.getAdressCode(obj);
        if (res.code == 200) {
          this.form.provCode = res.data.provCode; //省
          this.form.cityCode = res.data.cityCode; //市
          this.cascaderValue = res.data.cityCode; //市

          this.form.expectProvCode = res.data.provCode; //预期养老地区-省
          this.form.expectCityCode = res.data.cityCode; //预期养老地区-市
        } else {
          this.$toast(res.msg);
          return false
        }
      },
      //获取用户位置
      getUserAddress() {
        let that = this;
        import('@/api/wechat.js').then(mod => {
          let wx = mod.default
          wx.init()
          //获取用户位置
          wx.getLocations(function(res) {
            // console.log('adress:', res);
            //直辖市只在省级显示，不在市级显示
            if (res.split('-')[0] == '北京') {
              that.getAdressCodes('北京', '北京');
              that.form.addressName = '北京-北京';
              that.form.expectRegion = '北京-北京'; //预期养老地区
            } else if (res.split('-')[0] == '天津') {
              that.getAdressCodes('天津', '天津');
              that.form.addressName = '天津-天津';
               that.form.expectRegion = '天津-天津'; //预期养老地区
            } else if (res.split('-')[0] == '重庆') {
              that.getAdressCodes('重庆', '重庆');
              that.form.addressName = '重庆-重庆';
              that.form.expectRegion = '重庆-重庆'; //预期养老地区
            } else if (res.split('-')[0] == '上海') {
              that.getAdressCodes('上海', '上海');
              that.form.addressName = '上海-上海';
              that.form.expectRegion = '上海-上海'; //预期养老地区
            } else {
              that.form.addressName = res;
              that.form.expectRegion = res; //预期养老地区
              that.getAdressCodes(res.split('-')[0], res.split('-')[1]);
            }

          })

        })
      },
      //行业选择
      industryOnConfirm(val) {
        this.form.occClassCode = val.id
        this.form.occClassName = val.text
        this.industryPop = false
      },
      //预期退休年龄选择
      onAge(t, item) {
        //如果，选项年龄大于选择的年龄
        if (!t) {
          let inputAge = this.form.inputAge == '' ? 0 : this.form.inputAge;
          if (Number(item.id) > Number(inputAge)) {
            this.form.retiredAge = item.id
          } else {
            // this.form.retiredAge=''
          }
        }

      },
      //开始测算
      // toStart(){
      async toStart() {
        if (this.form.sex == '') {
          this.$toast('请选择性别!');
          return false
        }
        if (this.form.inputAge == '') {
          this.$toast('请选择年龄!');
          return false
        }
        if (this.form.addressName == '') {
          this.$toast('请选择地区!');
          return false
        }
        if (this.form.monthIncome == '') {
          this.$toast('请选择月收入!');
          return false
        }
        if (this.isPerson != 'Y' && this.form.isSocialsecurity != 'Y' && this.form.pensionMethod !=
          'childrenAssisted') {
          this.$toast('请选择退休后养老资金来源');
          return false
        }
        this.form.izPerson = this.isPerson;
        let res = await api.getInfo(this.form);
        if (res.code == 200) {
          this.$store.commit('setReportTabIndex', 0);
          this.$store.commit('setReportTabIndexs', 0);
          localStorage.setItem("reportData", JSON.stringify(res.data))
          localStorage.setItem("formData", JSON.stringify(this.form))
          this.$router.push({
            path: '/home'
          });
        } else {
          this.$toast(res.msg);
          return false
        }
      },
    }
  }
</script>
<style scoped>
  @import url("../../assets/style/public.css");
  @import url("../../assets/style/form.css");

  /* 选择器样式 */
  /deep/.van-popup--bottom {
    width: 92%;
    left: 4%;
  }

  /deep/.van-picker__cancel {
    font-size: 1.6rem;
    font-weight: 400;
    color: #D4D4D4;
  }

  /deep/.van-picker__confirm {
    font-size: 1.6rem;
    font-weight: 400;
    color: #5A5860;
  }

  /deep/.van-picker__toolbar {
    padding-top: 3rem;
  }

  /deep/.van-picker__title {
    font-size: 2rem;
    font-weight: 400;
    font-family: 'FZLTZHJW';
    color: #302E38;
  }

  /deep/ .van-picker-column__item--selected {
    background: linear-gradient(271deg, #FFFFFF 0%, #FFF5F2 67%, #FEDBD2 100%);
    position: relative;
    font-size: 1.8rem;
    font-family: 'FZLTZHJW';
    /* font-weight: bold; */
    color: #F84417;
  }

  /deep/ .van-picker-column__item--selected::after {
    position: absolute;
    left: 0;
    top: calc(50% - 13px);
    display: block;
    content: '';
    width: 4px;
    height: 26px;
    background: #F84417;
    border-radius: 3px;
  }


  /* 地区下拉菜单 */
  /deep/.van-cascader__title {
    font-size: 2rem;
    font-weight: bold;
    color: #302E38;
    box-sizing: border-box;
    padding: 3rem 0 0;
  }

  /deep/.van-cascader__header {
    box-sizing: border-box;
    padding-left: 2.3rem;
    margin-bottom: 2rem;
  }

  /deep/.van-tab {
    font-size: 1.6rem;
  }

  /deep/.van-tabs__line {
    width: 1.7rem;
    height: .2rem;
    /* left: calc(50% - 0.85rem); */
    background-color: #302E38;
  }

  /deep/.van-cascader__selected-icon {
    display: none;
  }

  /deep/.van-cascader__option span {
    font-size: 1.6rem;
    font-weight: 400;
    color: #302E38;
  }

  /deep/.van-cascader__option--selected {
    background: linear-gradient(271deg, #FFFFFF 0%, #FFF5F2 67%, #FEDBD2 100%);
    border-radius: 0;
    height: 4rem;
    position: relative;
  }

  /deep/.van-cascader__option--selected::after {
    display: block;
    content: '';
    width: .4rem;
    height: 2.6rem;
    background: #F84417;
    border-radius: .3rem;
    position: absolute;
    left: 0;
    top: calc(50% - 1.3rem);
    z-index: 1;
  }

  /deep/.van-cascader__option--selected span {
    color: #F8461A;
    font-weight: bold;
  }
</style>
