<template>
  <div class="text3D2" :style="{'height':heights+'px'}">

    <div class="preloader" :style="{'height':heights+'px'}">
      <div class="imgBox">
        <img src="@/assets/img/login1.png" class="img" />
      </div>
      <div class="preloader__ring">
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">10</div>
        <div class="preloader__sector">0</div>
        <div class="preloader__sector">0+</div>
        <div class="preloader__sector">宏</div>
        <div class="preloader__sector">观</div>
        <div class="preloader__sector">经</div>
        <div class="preloader__sector">济</div>
        <div class="preloader__sector">参</div>
        <div class="preloader__sector">数</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">个</div>
        <div class="preloader__sector">性</div>
        <div class="preloader__sector">化</div>
        <div class="preloader__sector">专</div>
        <div class="preloader__sector">属</div>
        <div class="preloader__sector">养</div>
        <div class="preloader__sector">老</div>
        <div class="preloader__sector">方</div>
        <div class="preloader__sector">案</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">20</div>
        <div class="preloader__sector">0</div>
        <div class="preloader__sector">+</div>
        <div class="preloader__sector">前</div>
        <div class="preloader__sector">沿</div>
        <div class="preloader__sector">文</div>
        <div class="preloader__sector">献</div>
        <div class="preloader__sector">模</div>
        <div class="preloader__sector">型</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">智</div>
        <div class="preloader__sector">能</div>
        <div class="preloader__sector">大</div>
        <div class="preloader__sector">数</div>
        <div class="preloader__sector">据</div>
        <div class="preloader__sector">精</div>
        <div class="preloader__sector">算</div>
        <div class="preloader__sector">模</div>
        <div class="preloader__sector">型</div>
      </div>
      <div class="preloader__ring">
        <div class="preloader__sector">个</div>
        <div class="preloader__sector">性</div>
        <div class="preloader__sector">化</div>
        <div class="preloader__sector">专</div>
        <div class="preloader__sector">属</div>
        <div class="preloader__sector">养</div>
        <div class="preloader__sector">老</div>
        <div class="preloader__sector">方</div>
        <div class="preloader__sector">案</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">智</div>
        <div class="preloader__sector">能</div>
        <div class="preloader__sector">大</div>
        <div class="preloader__sector">数</div>
        <div class="preloader__sector">据</div>
        <div class="preloader__sector">精</div>
        <div class="preloader__sector">算</div>
        <div class="preloader__sector">模</div>
        <div class="preloader__sector">型</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">10</div>
        <div class="preloader__sector">0</div>
        <div class="preloader__sector">0+</div>
        <div class="preloader__sector">宏</div>
        <div class="preloader__sector">观</div>
        <div class="preloader__sector">经</div>
        <div class="preloader__sector">济</div>
        <div class="preloader__sector">参</div>
        <div class="preloader__sector">数</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">20</div>
        <div class="preloader__sector">0</div>
        <div class="preloader__sector">+</div>
        <div class="preloader__sector">前</div>
        <div class="preloader__sector">沿</div>
        <div class="preloader__sector">文</div>
        <div class="preloader__sector">献</div>
        <div class="preloader__sector">模</div>
        <div class="preloader__sector">型</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
        <div class="preloader__sector">&nbsp;</div>
      </div>
    </div>
    <img src="@/assets/img/loginBg2.png" class="img1" />
    <img src="@/assets/img/quan.png" class="img2" />
    <img src="@/assets/img/loginbgs3.png" class="img3 img3_1" ref="img3_1" />
    <img src="@/assets/img/loginbgs2.png" class="img3 img3_2" ref="img3_2" />
    <img src="@/assets/img/loginbgs1.png" class="img3 img3_3" ref="img3_3" />
  </div>
</template>

<script>
  export default {
    props:{
      'dataHeight':{
        type:Number,
      }
    },
    watch: {
      'dataHeight': {
        handler(newVal, oldVal) {
          this.heights = newVal
        },
        deep: true
      },
    },
    data() {
      return {
        heights:0,
      }
    },
    computed: {},
    mounted() {
      this.heights = this.dataHeight
      this.addDonghua()
    },
    beforeDestroy() {},
    methods: {
      addDonghua() {
        let that = this;
        setTimeout(function() {
          that.$refs.img3_1.classList.add('active')
        }, 0)
        setTimeout(function() {
          that.$refs.img3_2.classList.add('active')
        }, 1000)
        setTimeout(function() {
          that.$refs.img3_3.classList.add('active')
        }, 2000)
        setTimeout(function() {
          that.removeDonghua()
        }, 3000)
      },
      removeDonghua() {
        let that = this;
        that.$refs.img3_1.classList.remove('active')
        that.$refs.img3_2.classList.remove('active')
        that.$refs.img3_3.classList.remove('active')
        setTimeout(function() {
          that.addDonghua()
        }, 500)
      }
    }
  }
</script>
<style scoped>
  @import url("../../../assets/style/login3D2.css");
</style>
