<template>
  <div class="tables">
    <div class="tableTab" ref="tableTab" >
      <div class="tableTabBox">
        <div v-for="(item,index) in tabs" :key="index" v-html="item" :class="{'on':active==index}"
          @click="onTabs(index)">
        </div>
      </div>
    </div>
    <!-- <div v-if="active==0" class="tablesCont">
          <div class="tablesBox">
            <div class="tablesBoxHead">
              <div class="tablesBoxTr">
                <div class="tablesBoxTh">年金险</div>
                <div class="tablesBoxTh">两全险</div>
                <div class="tablesBoxTh">寿险</div>
              </div>
            </div>
            <div class="tablesBoxBody">
              <div class="tablesBoxTr">
                <div class="tablesBoxTd1">父母</div>
                <div class="tablesBoxTd1">少儿</div>
                <div class="tablesBoxTd1">本人</div>
              </div>
              <div class="tablesBoxTr">
                <div class="tablesBoxTd3">养老储备</div>
                <div class="tablesBoxTd3">财富增值</div>
                <div class="tablesBoxTd3">身故保证</div>
              </div>
            </div>
          </div>
          <div class="tablesBox">
            <div class="tablesBoxHead">
              <div class="tablesBoxTr tablesBoxThs">
                <div class="tablesBoxTh">重疾险</div>
                <div class="tablesBoxTh">医疗险</div>
                <div class="tablesBoxTh">意外险</div>
              </div>
            </div>
            <div class="tablesBoxBody">
              <div class="tablesBoxTr">
                <div class="tablesBoxTd1">本人</div>
                <div class="tablesBoxTd1">本人</div>
                <div class="tablesBoxTd1">家庭</div>
              </div>
              <div class="tablesBoxTr">
                <div class="tablesBoxTd2">重疾康复<br/>收入补偿</div>
                <div class="tablesBoxTd2">健康医疗<br/>健康检测</div>
                <div class="tablesBoxTd2">意外伤害</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="active==1" class="tablesCont">
            <div class="tablesBox">
              <div class="tablesBoxHead">
                <div class="tablesBoxTr">
                  <div class="tablesBoxTh">年金险</div>
                  <div class="tablesBoxTh">两全险</div>
                  <div class="tablesBoxTh">寿险</div>
                </div>
              </div>
              <div class="tablesBoxBody">
                <div class="tablesBoxTr">
                  <div class="tablesBoxTd1">父母&nbsp;|&nbsp;本人&nbsp;|&nbsp;配偶</div>
                  <div class="tablesBoxTd1">家庭</div>
                  <div class="tablesBoxTd1">本人&nbsp;|&nbsp;配偶</div>
                </div>
                <div class="tablesBoxTr">
                  <div class="tablesBoxTd3">养老储备</div>
                  <div class="tablesBoxTd3">财富增值</div>
                  <div class="tablesBoxTd3">身故保障</div>
                </div>
              </div>
            </div>

            <div class="tablesBox">
              <div class="tablesBoxHead">
                <div class="tablesBoxTr tablesBoxThs">
                  <div class="tablesBoxTh">重疾险</div>
                  <div class="tablesBoxTh">医疗险</div>
                  <div class="tablesBoxTh">意外险</div>
                </div>
              </div>
              <div class="tablesBoxBody">
                <div class="tablesBoxTr">
                  <div class="tablesBoxTd1">本人&nbsp;|&nbsp;配偶</div>
                  <div class="tablesBoxTd1">本人&nbsp;|&nbsp;配偶</div>
                  <div class="tablesBoxTd1">家庭</div>
                </div>
                <div class="tablesBoxTr">
                  <div class="tablesBoxTd2">重疾康复<br/>收入补偿</div>
                  <div class="tablesBoxTd2">健康医疗<br/>健康检测</div>
                  <div class="tablesBoxTd2">意外伤害</div>
                </div>
              </div>
            </div>
        </div>
        <div v-else-if="active==2" class="tablesCont">
            <div class="tablesBox">
              <div class="tablesBoxHead">
                <div class="tablesBoxTr">
                  <div class="tablesBoxTh">年金险</div>
                  <div class="tablesBoxTh">两全险</div>
                  <div class="tablesBoxTh">寿险</div>
                </div>
              </div>
              <div class="tablesBoxBody">
                <div class="tablesBoxTrs clear">
                  <div class="tablesBoxTd4 fl">
                    <div class="tablesBoxTd1">父母&nbsp;|&nbsp;本人&nbsp;|&nbsp;配偶</div>
                    <div class="tablesBoxTd3">养老储备</div>
                  </div>
                  <div class="tablesBoxTd4 fl">
                    <div class="tablesBoxTd1">少儿</div>
                    <div class="tablesBoxTd3">抚养教育</div>
                  </div>
                  <div class="tablesBoxTd5 fr">
                    <div class="tablesBoxTd1">本人&nbsp;|&nbsp;配偶</div>
                    <div class="tablesBoxTd6">身故保障</div>
                  </div>
                  <div class="tablesBoxTd4 fl">
                    <div class="tablesBoxTd1">少儿</div>
                    <div class="tablesBoxTd3">抚养教育</div>
                  </div>
                  <div class="tablesBoxTd4 fl">
                    <div class="tablesBoxTd1">家庭</div>
                    <div class="tablesBoxTd3">财富增值</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tablesBox">
              <div class="tablesBoxHead">
                <div class="tablesBoxTr tablesBoxThs">
                  <div class="tablesBoxTh">重疾险</div>
                  <div class="tablesBoxTh">医疗险</div>
                  <div class="tablesBoxTh">意外险</div>
                </div>
              </div>
              <div class="tablesBoxBody">
                <div class="tablesBoxTr">
                  <div class="tablesBoxTd1">本人&nbsp;|&nbsp;配偶&nbsp;|&nbsp;少儿</div>
                  <div class="tablesBoxTd1">本人&nbsp;|&nbsp;配偶&nbsp;|&nbsp;少儿</div>
                  <div class="tablesBoxTd1">家庭</div>
                </div>
                <div class="tablesBoxTr">
                  <div class="tablesBoxTd2">重疾康复<br/>收入补偿</div>
                  <div class="tablesBoxTd2">健康医疗<br/>健康检测</div>
                  <div class="tablesBoxTd2">意外伤害</div>
                </div>
              </div>
            </div>
        </div>
<div v-else-if="active==3" class="tablesCont">
              <div class="tablesBox">
                <div class="tablesBoxHead">
                  <div class="tablesBoxTr">
                    <div class="tablesBoxTh">年金险</div>
                    <div class="tablesBoxTh">两全险</div>
                    <div class="tablesBoxTh">寿险</div>
                  </div>
                </div>
                <div class="tablesBoxBody">
                  <div class="tablesBoxTr">
                    <div class="tablesBoxTd1">本人&nbsp;|&nbsp;配偶</div>
                    <div class="tablesBoxTd1">家庭</div>
                    <div class="tablesBoxTd1">本人&nbsp;|&nbsp;配偶</div>
                  </div>
                  <div class="tablesBoxTr">
                    <div class="tablesBoxTd2">养老储备</div>
                    <div class="tablesBoxTd2">财富增值</div>
                    <div class="tablesBoxTd2">身故保障<br/>财富传承</div>
                  </div>
                </div>
              </div>
              <div class="tablesBox">
                <div class="tablesBoxHead">
                  <div class="tablesBoxTr tablesBoxThs">
                    <div class="tablesBoxTh">重疾险</div>
                    <div class="tablesBoxTh">医疗险</div>
                    <div class="tablesBoxTh">意外险</div>
                  </div>
                </div>
                <div class="tablesBoxBody">
                  <div class="tablesBoxTr">
                    <div class="tablesBoxTd1">本人&nbsp;|&nbsp;配偶</div>
                    <div class="tablesBoxTd1">本人&nbsp;|&nbsp;配偶</div>
                    <div class="tablesBoxTd1">家庭</div>
                  </div>
                  <div class="tablesBoxTr">
                    <div class="tablesBoxTd2">重疾康复<br/>收入补偿</div>
                    <div class="tablesBoxTd2">健康医疗<br/>健康检测</div>
                    <div class="tablesBoxTd2">意外伤害</div>
                  </div>
                </div>
              </div>
        </div>
        <div v-else-if="active==4" class="tablesCont">
            <div class="tablesBox">
              <div class="tablesBoxHead">
                <div class="tablesBoxTr">
                  <div class="tablesBoxTh">年金险</div>
                  <div class="tablesBoxTh">两全险</div>
                  <div class="tablesBoxTh">寿险</div>
                </div>
              </div>
              <div class="tablesBoxBody">
                <div class="tablesBoxTr">
                  <div class="tablesBoxTd1">本人&nbsp;|&nbsp;配偶</div>
                  <div class="tablesBoxTd1">家庭</div>
                  <div class="tablesBoxTd1">本人&nbsp;|&nbsp;配偶</div>
                </div>
                <div class="tablesBoxTr">
                  <div class="tablesBoxTd2">品质养老<br/>健康护理</div>
                  <div class="tablesBoxTd2">财富增值</div>
                  <div class="tablesBoxTd2">身故保障<br/>财富传承</div>
                </div>
              </div>
            </div>
            <div class="tablesBox">
              <div class="tablesBoxHead">
                <div class="tablesBoxTr tablesBoxThs">
                  <div class="tablesBoxTh">重疾险</div>
                  <div class="tablesBoxTh">医疗险</div>
                  <div class="tablesBoxTh">意外险</div>
                </div>
              </div>
              <div class="tablesBoxBody">
                <div class="tablesBoxTr">
                  <div class="tablesBoxTd1">本人&nbsp;|&nbsp;配偶</div>
                  <div class="tablesBoxTd1">本人&nbsp;|&nbsp;配偶</div>
                  <div class="tablesBoxTd1">家庭</div>
                </div>
                <div class="tablesBoxTr">
                  <div class="tablesBoxTd2">重疾康复<br/>收入补偿</div>
                  <div class="tablesBoxTd2">健康医疗<br/>健康监测</div>
                  <div class="tablesBoxTd2">意外伤害</div>
                </div>
              </div>
            </div>
        </div> -->

  </div>
</template>

<script>
  export default {
    data() {
      return {
        tabs: ['咿呀</br>学语期', '少年</br>求学期', '单身</br>贵族期', '二人</br>世界期', '家庭</br>成长期', '子女</br>离巢期', '安度</br>晚年期'],
        active: this.$store.state.reportTabIndexs,
        width:0,
        scrollLeft:0,
        // scrollLeft: this.$store.state.reportTabIndex > 3 ? this.width : 0,
      }
    },
    mounted() {
      this.detials()
    },
    watch: {
      '$store.state.reportTabIndexs': {
        handler(newVal, oldVal) {
          this.active = newVal
          if (newVal > 3) {
            this.$refs.tableTab.scrollLeft = this.width
          } else {
            this.$refs.tableTab.scrollLeft = 0
          }
          // console.log('tab',newVal)
        },
        deep: true
      },
    },
    methods: {
      detials(){
        // console.log(this.$store.state.reportTabIndexs)
        this.width = this.$refs.tableTab.scrollWidth;
        this.active = this.$store.state.reportTabIndexs;
        this.scrollLeft=this.$store.state.reportTabIndexs > 3 ?this.width : 0;
      },
      onTabs(index) {
        this.active = index;
        this.$store.commit('setReportTabIndex', index);
        // console.log('s2',index)
      }
    }
  }
</script>

<style scoped>
  @import url("../../../assets/style/tables.css");
</style>
