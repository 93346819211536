import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import '@/assets/style/reset.css'
import 'vant/lib/index.css'
Vue.use(Vant)
//element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false
// 百度echarts
import * as ECharts from "echarts" //引入echarts
Vue.prototype.$echarts = ECharts

// 在main.js中全局引入
// import VideoPlayer from 'vue-video-player/src';
// import 'vue-video-player/src/custom-theme.css'
// import 'video.js/dist/video-js.css'

// Vue.use(VideoPlayer)

import '@/api/jsmpeg.min'
const vue =new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vue
