<template>
  <div class="programme">
    <div class="programmeBox">
      <div class="programmeBoxL" ref="programme1">
        <p><span>{{detial.one.toFixed(1)}}</span><label>%</label>
        </p>
        <p>养老储备</p>
      </div>
      <div class="programmeBoxC">
        <div class="programmeBoxCBox" ref="programme2">
          <img src="@/assets/img/icons1.png" />
          <div class="programmeBoxCBoxT">
            <p>至少{{detial.two.toFixed(1)}}%</p>
            <p>商业养老保险</p>
          </div>
          <div class="programmeBoxCBoxR">
            <p>长期</p>
            <p>稳健</p>
            <p>安全</p>
          </div>
        </div>
        <div class="programmeBoxCBox" ref="programme3">
          <img src="@/assets/img/icons2.png" />
          <div class="programmeBoxCBoxT">
            <p>
              约{{(Number(detial.one) - Number(detial.two)).toFixed(1)}}%
            </p>
            <p>其他养老储蓄</p>
          </div>
          <div class="programmeBoxCBoxR">
            <p>波动</p>
            <p>灵活</p>
            <p>风险</p>
          </div>
        </div>
      </div>
    </div>
    <div class="programmeBoxB">
      <p>
        <span>{{(100 - Number(detial.one)).toFixed(1)}}</span><label>%</label>
      </p>
      <div><img src="@/assets/img/icons3.png" /><span>剩余可支配收入</span></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      datas: {
        type: Object,
      },
    },
    data() {
      return {
        detial: this.datas,
      }
    },
    mounted() {
     this.detials()
    },
    watch: {
      datas: {
        handler(newVal, oldVal) {
          // console.log('yueshourus',newVal.yueshourus)
          // console.log('one',newVal.one)
          if (newVal.yueshourus) { //月收入大于1万
            if (newVal.one < 5) {
              this.detial = {
                one: 5,
                two: 1.5,
              };
            } else if (newVal.one > 45) {
              this.detial = {
                one: 45,
                two: 13.5,
              };
            } else {
              this.detial = newVal;
            }
          } else {
            if (newVal.one < 5) {
              this.detial = {
                one: 5,
                two: 1.5,
              };
            } else if (newVal.one > 25) {
              this.detial = {
                one: 25,
                two: 7.5,
              };
            } else {
              this.detial = newVal;
            }
          }

        },
        deep: true
      },
      '$store.state.fadeOut': {
        handler(newVal, oldVal) {
          this.donghua();
        },
        deep: true
      },
      '$store.state.fadeOut1': {
        handler(newVal, oldVal) {
          this.detials();
        },
        deep: true
      },
    },
    methods: {
      detials(){
        console.log('yueshourus',this.datas.yueshourus)
        console.log('one',this.datas.one)
        if (this.datas.yueshourus) { //月收入大于1万
          if (this.datas.one < 5) {
            this.detial = {
              one: 5,
              two: 1.5,
            };
          } else if (this.datas.one > 45) {
            this.detial = {
              one: 45,
              two: 13.5,
            };
          } else {
            this.detial = this.datas;
          }
        } else {
          if (this.datas.one < 5) {
            this.detial = {
              one: 5,
              two: 1.5,
            };
          } else if (this.datas.one > 25) {
            this.detial = {
              one: 25,
              two: 7.5,
            };
          } else {
            this.detial = this.datas;
          }
        }
      },
      //动画
      donghua() {
        let that = this;
        setTimeout(function() {
          that.$refs.programme1.classList.add('programmeActive')
          setTimeout(function() {
            that.$refs.programme1.classList.remove('programmeActive')
          }, 2000)
        }, 1000)
        setTimeout(function() {
          that.$refs.programme2.classList.add('programmeActive')
          setTimeout(function() {
            that.$refs.programme2.classList.remove('programmeActive')
          }, 2000)
        }, 2000)
        setTimeout(function() {
          that.$refs.programme3.classList.add('programmeActive')
          setTimeout(function() {
            that.$refs.programme3.classList.remove('programmeActive')
          }, 2000)
        }, 3000)
      }
    }
  }
</script>

<style scoped>
  @import url("../../../assets/style/programme.css");
</style>
