<template>
  <div class="pop">
    <div class="popBox">
      <div class="popBoxClose">
        <img src="@/assets/img/popClose.png" @click.stop="$emit('closePop')" />
      </div>
      <div class="popBoxs1">
        <div class="popBoxHead">
          养老三支柱
        </div>
        <div class="popBoxBody">
          <div class="popBoxTr1">
            <div class="popBoxItem">
              <p>第一支柱</p>
              <span>基本养老保险</span>
            </div>
            <div class="popBoxItem">
              <p>第二支柱</p>
              <span>企、职业年金</span>
            </div>
            <div class="popBoxItem">
              <p>第三支柱</p>
              <span>个人储蓄型养老保险和商业养老保险</span>
            </div>
          </div>
          <div class="popBoxTr2">
            <div class="popBoxItem">
              政府主导
            </div>
            <div class="popBoxItem">
              单位主导
            </div>
            <div class="popBoxItem">
              灵活多样
            </div>
          </div>
          <div class="popBoxTr2">
            <div class="popBoxItem">
              基本保障
            </div>
            <div class="popBoxItem">
              补充保障
            </div>
            <div class="popBoxItem">
              充分保障
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      'texts': {
        type: String,
        default: ''
      }
    },
    data() {
      return {

      }
    }
  }
</script>

<style>
</style>
