<template>
  <div>
    <div class="chartsGl" id="charts" ref="main3"></div>
  </div>
</template>
<script>
  export default {
    props: {
      datas: {
        type: Object,
      }
    },
    data() {
      return {
        myChart3: null,
        interval:null,
        color: ['#FF7D5D', '#FEDBD2', '#D5D5D7'],
        option: {
          grid: {
            top: 'center',
            left: 'center',
          },
          color: ['#FF7D5D', '#FEDBD2', '#D5D5D7'],
          series: [{
              type: 'pie',
              radius: ['20%', '40%'],
               zlevel:2,
              // avoidLabelOverlap: false,
              // roseType: 'none',
              silent: 'ture', //图形是否不响应和触发鼠标事件，默认为 false，即响应和触发鼠标事件。
              itemStyle: {
                // 边框
                // borderColor: "#e6e6e6",
                // borderWidth: 2,
                borderRadius: "7%",
                // shadowColor: "rgba(0, 0, 0, 0.2)",
                // shadowOffsetY: 5
                //阴影
                shadowBlur: 10,
                shadowOffsetY: 10,
                shadowColor: 'rgba(0, 0, 0, 0.1)',

              },
              label: {
                show: true,
                lineHeight: 15,
                formatter(param) {
                  return `{value1|${param.name}}\n{value2|${param.value}} {value3|万元}`
                },
                rich: {
                  value1: {
                    fontSize: 12,
                    color: '#ACACB0',
                  },
                  value2: {
                    fontSize: 12,
                    color: '#302E38',
                    fontFamily: 'FZLTZHUNHJW',
                    verticalAlign: 'bottom',
                  },
                  value3: {
                    fontSize: 12,
                    color: '#302E38',
                    fontFamily: 'FZLTZHUNHJW',
                    verticalAlign: 'bottom',
                  },
                }
              },
              emphasis: {
                show: true,
              },
              labelLine: {
                show: true,
                length: 8,
                length2: 8,
              },
              data: []
            },
            // {
            //   name: 'border',
            //   type: 'pie',
            //    zlevel:1,
            //   clockWise: false,
            //   radius: ['30%', '50%'],
            //   center: ['50%', '50%'],
            //   animation: false,
            //   data: [{
            //     value: 0,
            //     color: '#fff',
            //     label: {
            //       show: false,
            //     },
            //     labelLine: {
            //       show: false,
            //     },
            //     emphasis: {
            //       disabled: true,
            //     },
            //     select: {
            //       disabled: true,
            //     },
            //     tooltip: {
            //       show: false,
            //     },
            //     itemStyle: {
            //       color: '#fff',
            //       shadowColor: 'rgba(0, 0, 0, 0.1)',
            //       shadowBlur: 10,
            //       shadowOffsetX: 0,
            //       shadowOffsetY: 10,
            //     },
            //   }, ],
            // }
          ]
        }
      };
    },
    watch: {
      '$store.state.echartsThree': {
        handler(newVal, oldVal) {
          this.getEcharts3(this.datas);
        },
        deep: true
      },
      // datas: {
      //   handler(newVal, oldVal) {
      //     console.log(3)
      //     this.getEcharts3(newVal);
      //   },
      //   deep: true
      // }
    },
    methods: {
      getEcharts3(val) {
        let that = this;

        // that.option.color = that.color;
        let seriesArry = []
        if(val.three.toFixed(0)>0){
          seriesArry.push({
            value: val.three.toFixed(0),
            name: '个人储备',
            // itemStyle:{
            // color: new this.$echarts.graphic.RadialGradient(1, 0.1, 1, [{
            //   color: '#ea451c',
            //     offset: 0
            //   },
            //   {
            //     color: '#f4886d',
            //     offset: 1
            //   }
            // ])}
          })
        }
        if(val.one.toFixed(0)>0){
          seriesArry.push({
            value: val.one.toFixed(0),
            name: '社保提供',
          })
        }
        if(val.two.toFixed(0)>0){
          seriesArry.push({
            value: val.two.toFixed(0),
            name: '家人支持',
          })
        }
        that.option.series[0].data = seriesArry;
        that.myChart3.clear();
        that.myChart3.setOption(that.option);
        // window.addEventListener("resize", function() {
        //   that.myChart3.resize();
        // });
      }
    },
    beforeDestroy() {
      window.clearInterval(this.interval)
    },
    mounted() {
      this.myChart3 = this.$echarts.init(this.$refs.main3);
      this.getEcharts3(this.datas);
      // let that = this;
      // //每隔半分钟执行一次
      // that.interval = setInterval(function(){
      //   that.getEcharts3(that.datas);
      // },30000)
    },
  };
</script>
<style scoped>
  .chartsGl {
    width: 100%;
    height: 300px;
  }
</style>
