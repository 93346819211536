<template>
  <div class="loginBox" v-show="boxShow" :style="{'min-height':viewHeight+'px'}">
    <img src="@/assets/img/logos.png" class="logo">
    <div class="index_header">
      <div class="loginQa">
        <p ref="loginQa1">准备多少养老资金才算充足？</p><br/>
        <p ref="loginQa2">退休前如何规划养老资金储备？</p><br/>
        <p ref="loginQa3">同类客群如何配置商业养老保险？</p>
      </div>
      <div class="loginTitle">
        <p>精算养老规划助手</p>
        <div class="loginSmall wavys">
          <span style="--i:1;"><i></i></span>
          <span style="--i:2;">您</span>
          <span style="--i:3;">的</span>
          <span style="--i:4;">家</span>
          <span style="--i:5;">庭</span>
          <span style="--i:6;">私</span>
          <span style="--i:7;">人</span>
          <span style="--i:8;">精</span>
          <span style="--i:9;">算</span>
          <span style="--i:10;">师</span>
          <span style="--i:11;"><i></i></span>
        </div>
      </div>
    </div>
    <!-- <canvas id="video" :style="{opacity: isLoaded ? 1 : 0}"></canvas> -->
    <div class="text3dAMBox" ref="mode" :style="{'height':clientHeight+'px'}">
      <text3d2 class="text3dAM" :dataHeight="clientHeight"></text3d2>
      <img src="@/assets/img/loginbgss1.png" class="laorenImg">
    </div>
    <a class="btn1" @click="toStart" v-if="btnShow">开始测评</a>
    <div v-else class="btns">
      <a @click="toReport">查看历史报告</a>
      <a @click="toStart">重新测评</a>
    </div>
    <div>
      <div class="loginTip2">
        已为{{num}}人规划养老储备方案
      </div>
      <div class="loginTip">
        *您的回答仅用于本次测评，不涉及收集和使用任何您的个人信息，请您放心使用
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api/index.js'
  import text3d2 from './components/text3d2.vue'
  export default {
    components: {
      text3d2
    },
    name: 'HelloWorld',
    data() {
      return {
        btnShow: true,
        WXopenid: '',
        detial: {},
        player: null,
        // isLoaded: false,
        boxShow: true,
        viewHeight: window.outerHeight,
        num: 0,
        clientWidth:0,
        clientHeight:0,
      }
    },
    mounted() {
      //静默授权获取openid
      this.shouquan()
      // this.getlogins(123)
      this.clientWidth = this.$refs.mode.clientWidth;
      console.log(this.clientWidth/1.38)
      this.clientHeight = (this.clientWidth / 1.38)
      console.log(this.clientHeight)
    },
    methods: {
      async totals() {
        let res = await api.getSubmitCount();
        if (res.code == 200) {
          this.num = res.data
        } else {
          this.$toast(res.msg);
          return false
        }
      },
      async getlogins(index) {
        let res = await api.getlogin({
          openid: index
        });
        if (res.code == 200) {
          //有记录
          if (res.data) {
            this.detial = res.data;
            localStorage.setItem("formData", JSON.stringify(res.data))
             this.btnShow = false;
          } else { //没有记录
            this.btnShow = true;
          }
          // this.isLoaded = true;
          this.boxShow = true;
          // 视频动画加载
          this.AddDonghua()
          this.totals()
        } else {
          this.$toast(res.msg);
          return false
        }
      },
      async shouquan() {
        if (this.isWeiXin()) {
          const openid = this.getUrlParam("openid"); // 截取路径中的code
          if (openid == null || openid === "") {
            window.location.href =
              "https://weixin.e-chinalife.com/mic/oauth?officialid=gh_b47c5b804348&sub=false&wsp_param=openid,unionid&linkUrl=https://actuary.bfstor.com/prod-api/wechat/receiveWspParam";
          } else {
            //判断用户是否登录过
            this.WXopenid = openid;
            sessionStorage.setItem("openid", openid)
            this.getlogins(this.WXopenid)
            import('@/api/wechat.js').then(mod => {
              let wx = mod.default
              wx.init()
              //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
              wx.setUpdateTimelineShareData()
              //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
              wx.setUpdateAppMessageShareData()
            })
          }
        } else {
          // this.boxShow = true;
          this.boxShow = false;
          alert('请使用微信内置浏览器打开')
        }
      },
      // 判断是否是微信浏览器
      isWeiXin() {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          return true;
        } else {
          return false;
        }
      },
      //获取地址栏参数
      getUrlParam(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
        var r = window.location.search.substr(1).match(reg); //匹配目标参数
        if (r != null) return unescape(r[2]);
        return null; //返回参数值
      },
      toStart() {
        if (!this.WXopenid) {
          this.$toast('未获取到openid，请联系管理员');
          return false
        }
        this.$router.push({
          path: '/form'
        });
      },
      AddDonghua() {
        let that = this;
        setTimeout(function() {
          that.$refs.loginQa1.classList.add('donghua')
        }, 800)
        setTimeout(function() {
          that.$refs.loginQa2.classList.add('donghua')
        }, 1600)
        setTimeout(function() {
          that.$refs.loginQa3.classList.add('donghua')
        }, 2400)
      },
      async toReport() {
        if (!this.WXopenid) {
          this.$toast('未获取到openid，请联系管理员');
          return false
        }
        let res = await api.getlogin({
          openid: this.WXopenid
        });
        if (res.code == 200) {
          this.$store.commit('setReportTabIndex', 0);
          this.$store.commit('setReportTabIndexs', 0);
          localStorage.setItem("formData", JSON.stringify(res.data))
          console.log('logoData', res.data)
          this.detials(res.data)
        } else {
          this.$toast(res.msg);
          return false
        }

      },
      async detials(forms) {
        let res = await api.getInfo(forms);
        if (res.code == 200) {
          localStorage.setItem("reportData", JSON.stringify(res.data))
          console.log('reportData', res.data)
          let that = this;
          setTimeout(function() {
            that.$router.push({
              path: '/report',
              query: {
                type: 0
              }
            });
          }, 1000);
        } else {
          this.$toast(res.msg);
          return false
        }

      },
    }
  }
</script>
<style scoped>
  @import url("../../assets/style/login.css");
</style>
